import imageCompression from 'browser-image-compression';

export const optimizeImage = async (file) => {
    if (!file) return null;
    
    // Compression options
    const options = {
        maxSizeMB: 1,
        maxWidthOrHeight: 1200,
        useWebWorker: true,
        fileType: 'image/jpeg',
        quality: 0.9 // Adjust quality for better file size
    };

    try {
        // Compress the image
        const compressedFile = await imageCompression(file, options);
        
        // Create a new file with a standardized name
        return new File([compressedFile], 'processed-image.jpg', {
            type: 'image/jpeg'
        });
    } catch (error) {
        console.error('Error optimizing image:', error);
        throw error;
    }
};

export const createImagePreview = async (file) => {
    if (!file) return null;
    return URL.createObjectURL(file);
};

export const calculateInitialCrop = async (imageUrl) => {
    const img = new Image();
    const imgPromise = new Promise((resolve) => {
        img.onload = () => resolve(img);
    });
    img.src = imageUrl;
    await imgPromise;
    
    // Get the actual container dimensions from the parent element
    const container = document.querySelector('.ReactCrop__child-wrapper');
    const containerRect = container.getBoundingClientRect();
    
    // Calculate crop size using the smaller container dimension minus 10px
    const cropSize = Math.min(containerRect.width, containerRect.height) - 10;
    
    // Center the crop both horizontally and vertically
    const x = (containerRect.width - cropSize) / 2;
    const y = (containerRect.height - cropSize) / 2;
    
    console.log('Container and crop:', {
        container: {
            width: containerRect.width,
            height: containerRect.height
        },
        crop: {
            size: cropSize,
            x,
            y
        }
    });
    
    return {
        unit: 'px',
        width: cropSize,
        height: cropSize,
        x,
        y,
        aspect: 1
    };
};

export const getCroppedImage = async (imageSrc, crop) => {
    const image = new Image();
    image.src = imageSrc;
    await new Promise((resolve) => { image.onload = resolve; });

    // Get the actual container and its dimensions
    const container = document.querySelector('.ReactCrop__child-wrapper');
    const containerRect = container.getBoundingClientRect();

    // Get the displayed image element to get its actual dimensions
    const displayedImg = container.querySelector('img');
    const displayedRect = displayedImg.getBoundingClientRect();

    // Calculate the scale between original and displayed image
    const scaleX = image.naturalWidth / displayedRect.width;
    const scaleY = image.naturalHeight / displayedRect.height;

    // Convert crop coordinates to original image scale
    const originalCrop = {
        x: crop.x * scaleX,
        y: crop.y * scaleY,
        width: crop.width * scaleX,
        height: crop.height * scaleY
    };

    console.log('Crop dimensions:', {
        container: {
            width: containerRect.width,
            height: containerRect.height
        },
        displayed: {
            width: displayedRect.width,
            height: displayedRect.height
        },
        original: {
            width: image.naturalWidth,
            height: image.naturalHeight
        },
        scale: {
            x: scaleX.toFixed(3),
            y: scaleY.toFixed(3)
        },
        crop: originalCrop
    });

    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');

    // Set fixed output size for icon (200x200)
    const ICON_SIZE = 200;
    canvas.width = ICON_SIZE;
    canvas.height = ICON_SIZE;

    // Optional: Fill background with black (like Netflix icon)
    ctx.fillStyle = '#000000';
    ctx.fillRect(0, 0, ICON_SIZE, ICON_SIZE);

    // Draw the cropped portion scaled to 200x200
    ctx.drawImage(
        image,
        originalCrop.x,
        originalCrop.y,
        originalCrop.width,
        originalCrop.height,
        0,
        0,
        ICON_SIZE,
        ICON_SIZE
    );

    // Return as blob with quality suitable for icons
    return new Promise((resolve) => {
        canvas.toBlob((blob) => {
            resolve(blob);
        }, 'image/jpeg', 0.85);
    });
}; 