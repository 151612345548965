import React, { useState, useRef, useEffect } from 'react';
import { 
    Box, 
    Container,
    useTheme,
    Divider,
    Typography,
    Button
} from '@mui/material';
import Header from '../components/Header';
import HeroSlideshow from '../components/HeroSlideshow';
import UserConsole from '../components/UserConsole';
import CodeOwnerConsole from '../components/CodeOwnerConsole';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

function HomePage() {
    // Split view state
    const [splitPosition, setSplitPosition] = useState(50);
    const [isDragging, setIsDragging] = useState(false);
    const splitDividerRef = useRef(null);
    const containerRef = useRef(null);
    const theme = useTheme();

    const handleMouseDown = (e) => {
        setIsDragging(true);
        e.preventDefault();
    };

    useEffect(() => {
        const handleMouseMove = (e) => {
            if (!isDragging || !containerRef.current) return;
            
            const containerRect = containerRef.current.getBoundingClientRect();
            const newPosition = ((e.clientX - containerRect.left) / containerRect.width) * 100;
            
            const limitedPosition = Math.min(Math.max(newPosition, 30), 70);
            setSplitPosition(limitedPosition);
        };

        const handleMouseUp = () => {
            setIsDragging(false);
        };

        if (isDragging) {
            document.addEventListener('mousemove', handleMouseMove);
            document.addEventListener('mouseup', handleMouseUp);
        }

        return () => {
            document.removeEventListener('mousemove', handleMouseMove);
            document.removeEventListener('mouseup', handleMouseUp);
        };
    }, [isDragging]);

    return (
        <Box sx={{ 
            display: 'flex',
            flexDirection: 'column',
            minHeight: '100vh',
            backgroundColor: theme.palette.background.default  // Dark background for base container
        }}>
            <Header />
            
            {/* Light sections (Title + HeroSlideshow) */}
            <Box sx={{ 
                backgroundColor: theme.palette.background.paper  // Light background
            }}>
                {/* Title Box */}
                <Box sx={{ 
                    width: '100%',
                    backgroundColor: 'inherit',
                    my: 1,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    px: 2
                }}>
                    <Typography 
                        variant="h2" 
                        align="center"
                        sx={{ 
                            color: theme.palette.text.primary,
                            fontSize: { xs: '1.0rem', sm: '2.1rem' },
                            pt: { xs: 9, sm: 12 },
                            pb: { xs: 0.3, sm: 0.3 },
                            px: { xs: 0.5, sm: 2 },
                            mb: 0,
                            mt: 0,
                            fontWeight: 700,
                            width: '100%',
                            textAlign: 'center',
                            maxWidth: '1200px',
                            lineHeight: { xs: 0.8, sm: 'normal' },
                            '& .lineBreak': {
                                display: { xs: 'block', sm: 'none' }
                            }
                        }}
                    >
                        Connect with Products and Services<span className="lineBreak"><br /></span>You Need
                    </Typography>
                </Box>

                {/* HeroSlideshow Container */}
                <Container 
                    maxWidth="lg"
                    disableGutters
                    sx={{ 
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        width: '100%',
                        my: 0,
                        mb: { xs: 2, sm: 0 },
                        px: { xs: 0, sm: 3 },
                        py: { xs: 1, sm: 2 },
                        backgroundColor: theme.palette.background.paper
                    }}
                >
                    <HeroSlideshow />
                </Container>
            </Box>

            {/* Dark sections (everything after HeroSlideshow) */}
            <Box sx={{ 
                flex: 1,
                backgroundColor: theme.palette.background.default,  // Dark background
                width: '100%'
            }}>
                <Container 
                    maxWidth={false}
                    disableGutters
                    sx={{
                        width: '100%',
                        px: { xs: 2, sm: 3 },
                        py: { xs: 2, sm: 3 },
                        boxSizing: 'border-box',
                        backgroundColor: theme.palette.background.default
                    }}
                >
                    <Box 
                        ref={containerRef}
                        sx={{ 
                            display: 'flex',
                            flexDirection: { xs: 'column', md: 'row' },
                            minHeight: { xs: 'auto', md: 'calc(100vh - 300px)' },
                            position: 'relative',
                            cursor: isDragging ? 'col-resize' : 'default',
                            gap: { xs: 1, md: 0 },
                            px: 0,
                            mt: 0,
                            mb: 0,
                            '& > *': { my: 0 }
                        }}
                    >
                        {/* Left/Top - User Console */}
                        <Box 
                            sx={{ 
                                width: { xs: '100%', md: `${splitPosition}%` },
                                pr: { xs: 0, md: 3 },
                                position: 'relative'
                            }}
                        >
                            <UserConsole />
                        </Box>

                        {/* Added Divider for mobile */}
                       
                         <Divider 
                            sx={{ 
                                display: { xs: 'block', sm: 'none' },  // Only show on mobile
                                mt: 1,  // Margin top and bottom
                                width: '100%',
                                borderColor: theme.palette.separator.background
                            }} 
                        />
                         
                        
                        {/* Draggable Divider - Only show on desktop */}
                        {/* Hide divider on mobile */}
                        <Box
                            ref={splitDividerRef}
                            onMouseDown={handleMouseDown}
                            sx={{
                                display: { xs: 'none', md: 'flex' },
                                position: 'absolute',
                                left: `${splitPosition}%`,
                                top: 0,
                                bottom: 0,
                                width: '24px',
                                transform: 'translateX(-50%)',
                                cursor: 'col-resize',
                                alignItems: 'center',
                                justifyContent: 'center',
                                zIndex: 2,
                                '&:hover': {
                                    '.divider-line': {
                                        backgroundColor: theme.palette.separator.hover,
                                    },
                                    '.chevron-icon': {
                                        color: theme.palette.separator.hover,
                                    }
                                }
                            }}
                        >
                            <Box sx={{ 
                                display: 'flex', 
                                alignItems: 'center',
                                height: '100%',
                                position: 'relative'
                            }}>
                                <Box
                                    className="divider-line"
                                    sx={{
                                        width: '2px',
                                        height: '100%',
                                        backgroundColor: theme.palette.separator.background,
                                        transition: theme.transitions.create('background-color'),
                                    }}
                                />
                                <Box sx={{
                                    position: 'absolute',
                                    display: 'flex',
                                    gap: '2px',
                                    transform: 'translateX(-50%)',
                                    left: '50%'
                                }}>
                                    <ChevronLeftIcon 
                                        className="chevron-icon"
                                        sx={{ 
                                            color: theme.palette.separator.icon,
                                            transition: theme.transitions.create('color'),
                                            fontSize: '20px'
                                        }}
                                    />
                                    <ChevronRightIcon 
                                        className="chevron-icon"
                                        sx={{ 
                                            color: theme.palette.separator.icon,
                                            transition: theme.transitions.create('color'),
                                            fontSize: '20px'
                                        }}
                                    />
                                </Box>
                            </Box>
                        </Box>

                        {/* Right/Bottom - Code Owner Console */}
                        <Box 
                            sx={{ 
                                width: { xs: '100%', md: `${100 - splitPosition}%` },
                                pl: { xs: 0, md: 3 },
                                overflow: 'auto',
                                position: 'relative',
                             //   backgroundColor: theme.palette.background.tertiary,
                                zIndex: 1
                            }}
                        >
                            <CodeOwnerConsole />
                        </Box>
                    </Box>
                </Container>
            </Box>

            {/* Footer */}
            <Box 
                sx={{ 
                    width: '100%',
                    backgroundColor: theme.palette.footer.background,
                    py: 3
                }}
            >
                <Container maxWidth="lg">
                    <Box sx={{ 
                        display: 'flex', 
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        flexWrap: 'wrap',
                        gap: 2
                    }}>
                        <Typography 
                            variant="body2" 
                            sx={{ 
                                color: theme.palette.footer.text,
                                fontSize: { xs: '0.7rem', sm: '0.875rem' }
                            }}
                        >
                            © 2024 Get-Code. All rights reserved.
                        </Typography>
                        <Box sx={{ 
                            display: 'flex', 
                            gap: { xs: 2, sm: 3 },
                            flexWrap: 'wrap'
                        }}>
                            <Typography 
                                variant="body2" 
                                sx={{ 
                                    color: theme.palette.footer.text,
                                    fontSize: { xs: '0.7rem', sm: '0.875rem' },
                                    cursor: 'pointer',
                                    transition: theme.transitions.create('color'),
                                    '&:hover': { 
                                        color: theme.palette.text.primary 
                                    }
                                }}
                            >
                                Terms of Service
                            </Typography>
                            <Typography 
                                variant="body2" 
                                sx={{ 
                                    color: theme.palette.footer.text,
                                    fontSize: { xs: '0.75rem', sm: '0.875rem' },
                                    cursor: 'pointer',
                                    transition: theme.transitions.create('color'),
                                    '&:hover': { 
                                        color: theme.palette.text.primary 
                                    }
                                }}
                            >
                                Privacy Policy
                            </Typography>
                            <Typography 
                                variant="body2" 
                                sx={{ 
                                    color: theme.palette.footer.text,
                                    fontSize: { xs: '0.75rem', sm: '0.875rem' },
                                    cursor: 'pointer',
                                    transition: theme.transitions.create('color'),
                                    '&:hover': { 
                                        color: theme.palette.text.primary 
                                    }
                                }}
                            >
                                Contact Us
                            </Typography>
                        </Box>
                    </Box>
                </Container>
            </Box>
        </Box>
    );
}

export default HomePage;
