import React, { useState } from 'react';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    Box,
    Typography,
    CircularProgress
} from '@mui/material';
import imageCompression from 'browser-image-compression';

const TARGET_SIZE = 500; // Target width/height in pixels
const MAX_FILE_SIZE = 5; // Max file size in MB
const ACCEPTED_TYPES = ['image/jpeg', 'image/png', 'image/webp'];

const ImageUploadDialog = ({ open, onClose, onImageReady }) => {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const [preview, setPreview] = useState(null);

    const optimizeImage = async (file) => {
        // Create an image element to get dimensions
        const img = new Image();
        const imgPromise = new Promise((resolve) => {
            img.onload = () => resolve(img);
        });
        img.src = URL.createObjectURL(file);
        await imgPromise;

        // Calculate scaling
        const scale = TARGET_SIZE / Math.max(img.width, img.height);
        const width = Math.round(img.width * scale);
        const height = Math.round(img.height * scale);

        // Compression options
        const options = {
            maxSizeMB: MAX_FILE_SIZE,
            maxWidthOrHeight: TARGET_SIZE,
            useWebWorker: true,
            fileType: 'image/jpeg' // Convert all images to JPEG
        };

        // Compress the image
        const compressedFile = await imageCompression(file, options);
        return compressedFile;
    };

    const handleFileSelect = async (e) => {
        setError('');
        const file = e.target.files[0];
        
        if (!file) return;
        
        if (!ACCEPTED_TYPES.includes(file.type)) {
            setError('Please upload a JPEG, PNG or WebP image');
            return;
        }

        if (file.size > MAX_FILE_SIZE * 1024 * 1024) {
            setError(`File size must be less than ${MAX_FILE_SIZE}MB`);
            return;
        }

        try {
            setLoading(true);
            
            // Optimize image
            const optimizedFile = await optimizeImage(file);
            
            // Show preview
            const previewUrl = URL.createObjectURL(optimizedFile);
            setPreview(previewUrl);
            
            // Pass the optimized file back
            onImageReady(optimizedFile);
        } catch (err) {
            setError('Error processing image');
            console.error('Image processing error:', err);
        } finally {
            setLoading(false);
        }
    };

    return (
        <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
            <DialogTitle>Upload Image</DialogTitle>
            <DialogContent>
                <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                    <Button
                        variant="outlined"
                        component="label"
                        disabled={loading}
                    >
                        Select Image
                        <input
                            type="file"
                            hidden
                            accept="image/*"
                            onChange={handleFileSelect}
                        />
                    </Button>

                    {error && (
                        <Typography color="error" variant="body2">
                            {error}
                        </Typography>
                    )}

                    {loading && (
                        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                            <CircularProgress />
                        </Box>
                    )}

                    {preview && (
                        <Box sx={{ 
                            width: TARGET_SIZE, 
                            height: TARGET_SIZE,
                            margin: '0 auto',
                            border: '1px solid #ccc',
                            borderRadius: 1,
                            overflow: 'hidden'
                        }}>
                            <img
                                src={preview}
                                style={{
                                    width: '100%',
                                    height: '100%',
                                    objectFit: 'contain'
                                }}
                                alt="Preview"
                            />
                        </Box>
                    )}
                </Box>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>Cancel</Button>
            </DialogActions>
        </Dialog>
    );
};

export default ImageUploadDialog; 