import { v4 as uuidv4 } from 'uuid';
import { createUser, verifyUser } from './api';

const generateUserId = () => {
    const userId = `WEB-${uuidv4().split('-')[0]}`;
    localStorage.setItem('userId', userId);
    return userId;
};

export const createUserIfNotExists = async (userId) => {
    try {
        console.log('Attempting to create/verify user:', userId);
        
        const userData = {
            uuid: userId,
            platform: 'web',
            encrypted_apple_id: 'n/a'
        };
        
        // First try to verify if user exists
        const existingUser = await verifyUser(userId);
        if (existingUser) {
            console.log('User exists:', existingUser);
            return existingUser;
        }
        
        // If not, create new user
        const newUser = await createUser(userData);
        console.log('User created:', newUser);
        return newUser;
        
    } catch (error) {
        console.error('Error creating/verifying user:', error);
        localStorage.removeItem('userId');
        throw error;
    }
};

export const getUserId = async () => {
    let userId = localStorage.getItem('userId');
    if (!userId) {
        userId = generateUserId();
    }
    
    try {
        // Ensure user exists in database before returning ID
        await createUserIfNotExists(userId);
        return userId;
    } catch (error) {
        console.error('Error ensuring user exists:', error);
        // If there's an error, generate new ID and try again
        userId = generateUserId();
        await createUserIfNotExists(userId);
        return userId;
    }
};

export const getStoredUserId = () => {
    return localStorage.getItem('userId');
}; 