import React, { useState, useEffect, useCallback, useRef, useMemo } from 'react';
import { 
    Box, 
    Typography, 
    Paper, 
    Button, 
    ToggleButton, 
    ToggleButtonGroup,
    IconButton,
    CircularProgress,
    Select,
    MenuItem,
    FormControl,
    InputLabel,
    useTheme
} from '@mui/material';
import { 
    BarChart,
    Bar,
    XAxis, 
    YAxis, 
    CartesianGrid, 
    Tooltip, 
    ResponsiveContainer,
    Label
} from 'recharts';
import RefreshIcon from '@mui/icons-material/Refresh';
import TodayIcon from '@mui/icons-material/Today';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { getClickStats } from '../../services/codeManagementApi';
import { debounce } from 'lodash';

// Add this helper function at the top of the file, before the component
Date.prototype.getWeek = function() {
    const d = new Date(Date.UTC(this.getFullYear(), this.getMonth(), this.getDate()));
    const dayNum = d.getUTCDay() || 7;
    d.setUTCDate(d.getUTCDate() + 4 - dayNum);
    const yearStart = new Date(Date.UTC(d.getUTCFullYear(),0,1));
    return Math.ceil((((d - yearStart) / 86400000) + 1)/7);
};

const STORAGE_KEY = 'codeOwnerClickStats';
const WEEK_IN_MS = 7 * 24 * 60 * 60 * 1000;
const MONTH_IN_MS = 30 * 24 * 60 * 60 * 1000;

// Add this helper at the top with other utility functions
const isWeekend = (dateStr) => {
    const date = new Date(dateStr);
    const day = date.getDay();
    return day === 0 || day === 6; // 0 is Sunday, 6 is Saturday
};

// Add helper function at the top of the file
const isToday = (date) => {
    const today = new Date();
    return date.getFullYear() === today.getFullYear() &&
        date.getMonth() === today.getMonth() &&
        date.getDate() === today.getDate();
};

const getEndOfDay = (date) => {
    const endOfDay = new Date(date);
    endOfDay.setHours(23, 59, 59, 999);
    return endOfDay;
};

const CodeManagerDashboard = ({ codes }) => {
    const theme = useTheme();
    const [viewMode, setViewMode] = useState('week');
    const [selectedCode, setSelectedCode] = useState('all');
    const [processedStats, setProcessedStats] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);
    const lastRefreshRef = useRef(null);
    const [currentEndDate, setCurrentEndDate] = useState(new Date());
    const [canNavigateForward, setCanNavigateForward] = useState(false);
    const isDraggingRef = useRef(false);
    const lastMouseXRef = useRef(0);
    const chartContainerRef = useRef(null);
    const [isManualRefreshing, setIsManualRefreshing] = useState(false);
    const [updateTrigger, setUpdateTrigger] = useState(false);
    const lastSuccessfulFetchRef = useRef(null);

    // Load data from local storage
    const loadStoredStats = useCallback(() => {
        const stored = localStorage.getItem(STORAGE_KEY);
        return stored ? JSON.parse(stored) : [];
    }, []);

    // Save data to local storage
    const saveStats = useCallback((data) => {
        localStorage.setItem(STORAGE_KEY, JSON.stringify(data));
    }, []);

    // Process stats based on view mode and selected code
    const processStats = useCallback(() => {
        const rawStats = loadStoredStats();
        const endDate = currentEndDate;
        const startDate = new Date(endDate);
        
        if (viewMode === 'week') {
            startDate.setDate(endDate.getDate() - 7);
        } else {
            startDate.setDate(endDate.getDate() - 30);
        }

        // Update forward navigation possibility
        const now = new Date();
        setCanNavigateForward(endDate < now);

        let filteredStats = rawStats.filter(stat => {
            const statDate = new Date(stat.date);
            return statDate >= startDate && 
                   statDate <= endDate &&
                   (selectedCode === 'all' || stat.code_id === selectedCode);
        });

        const groupedStats = filteredStats.reduce((acc, stat) => {
            const date = stat.date.split('T')[0];
            acc[date] = (acc[date] || 0) + parseInt(stat.daily_clicks);
            return acc;
        }, {});

        const stats = [];
        let currentDate = new Date(startDate);
        while (currentDate <= endDate) {
            const dateStr = currentDate.toISOString().split('T')[0];
            stats.push({
                date: dateStr,
                clicks: groupedStats[dateStr] || 0
            });
            currentDate.setDate(currentDate.getDate() + 1);
        }

        setProcessedStats(stats);
    }, [viewMode, selectedCode, loadStoredStats, currentEndDate]);

    // Create stable fetch function with useMemo
    const debouncedFetchStats = useMemo(
        () => debounce(async (isManual = false) => {
            if (isLoading) return;
            
            if (isManual) {
                setIsManualRefreshing(true);
                // Reset last fetch time for manual refresh to get all data
                lastSuccessfulFetchRef.current = null;
            }
            setIsLoading(true);
            setError(null);
            
            try {
                const currentEnd = currentEndDate;
                const params = {
                    endDate: getEndOfDay(new Date()) // Use end of today
                };

                // Only add fromDate for auto-refresh
                if (!isManual && lastSuccessfulFetchRef.current) {
                    params.fromDate = new Date(lastSuccessfulFetchRef.current);
                }
                
                const response = await getClickStats(params);
                
                if (response?.data) {
                    if (isManual) {
                        // For manual refresh, replace all data
                        saveStats(response.data);
                    } else {
                        // For auto refresh, merge with existing data
                        const existingStats = loadStoredStats();
                        const newStats = response.data;
                        
                        // Create a map of existing stats
                        const existingStatsMap = new Map(
                            existingStats.map(stat => [
                                `${stat.date}_${stat.code_id}`,
                                stat
                            ])
                        );
                        
                        // Update or add new stats
                        newStats.forEach(stat => {
                            const key = `${stat.date}_${stat.code_id}`;
                            existingStatsMap.set(key, stat);
                        });
                        
                        // Convert map back to array
                        saveStats(Array.from(existingStatsMap.values()));
                    }
                    
                    lastRefreshRef.current = new Date();
                    lastSuccessfulFetchRef.current = new Date().getTime();
                    
                    // Only update currentEndDate if manual refresh or viewing latest
                    const now = new Date();
                    const endOfToday = getEndOfDay(now);
                    if (isManual || currentEnd >= endOfToday) {
                        setCurrentEndDate(endOfToday);
                    }
                    
                    processStats();
                    setUpdateTrigger(prev => !prev);
                }
            } catch (error) {
                console.error('Failed to fetch stats:', error);
                setError(error.response?.data?.message || 'Failed to load statistics');
            } finally {
                setIsLoading(false);
                if (isManual) setIsManualRefreshing(false);
            }
        }, 1000),
        [isLoading, processStats, saveStats, currentEndDate, loadStoredStats]
    );

    // Separate effect for processing stats when data changes
    useEffect(() => {
        const storedStats = loadStoredStats();
        if (storedStats.length > 0) {
            processStats(); // This will use current viewMode and currentEndDate
        }
    }, [loadStoredStats]); // Only reprocess when stored data changes

    // Keep existing view mode effect
    useEffect(() => {
        processStats();
    }, [viewMode, selectedCode, loadStoredStats, updateTrigger]); // Process when view parameters change

    // Auto refresh effect with stable interval
    useEffect(() => {
        debouncedFetchStats(false);
        
        const intervalId = setInterval(() => {
            debouncedFetchStats(false);
        }, 30000);

        return () => {
            clearInterval(intervalId);
            debouncedFetchStats.cancel();
        };
    }, []); // Empty dependencies since we're using stable function

    // Add navigation handlers
    const handleNavigate = useCallback((direction) => {
        setCurrentEndDate(prevDate => {
            const newDate = new Date(prevDate);
            const offset = viewMode === 'week' ? WEEK_IN_MS : MONTH_IN_MS;
            const now = new Date();
            const endOfToday = getEndOfDay(now);
            const oneYearAgo = new Date();
            oneYearAgo.setFullYear(oneYearAgo.getFullYear() - 1);
            
            if (direction === 'back') {
                const targetDate = new Date(newDate.getTime() - offset);
                return targetDate < oneYearAgo ? oneYearAgo : targetDate;
            } else {
                const targetDate = new Date(newDate.getTime() + offset);
                return targetDate > endOfToday ? endOfToday : targetDate;
            }
        });
        
        processStats();
    }, [viewMode, processStats]);

    // Modify Today button handler
    const handleToday = useCallback(() => {
        const now = new Date();
        const endOfToday = getEndOfDay(now);
        setCurrentEndDate(endOfToday);
        processStats();
    }, [processStats]);

    // Update useEffect for view mode changes
    useEffect(() => {
        setCurrentEndDate(new Date()); // Reset to current date when view mode changes
    }, [viewMode]);

    // Add drag handling functions
    const handleMouseDown = useCallback((e) => {
        e.preventDefault(); // Prevent text selection while dragging
        isDraggingRef.current = true;
        lastMouseXRef.current = e.clientX;
        if (chartContainerRef.current) {
            chartContainerRef.current.style.cursor = 'grabbing';
        }
    }, []);

    const handleMouseMove = useCallback((e) => {
        if (!isDraggingRef.current || !chartContainerRef.current) return;
        e.preventDefault(); // Prevent text selection while dragging

        const deltaX = e.clientX - lastMouseXRef.current;
        lastMouseXRef.current = e.clientX;

        // Get chart width and visible time range
        const chartWidth = chartContainerRef.current.offsetWidth;
        const timeRange = viewMode === 'week' ? 7 : 30; // days
        
        // Calculate how many milliseconds to move based on the pixel movement
        const msPerPixel = (timeRange * 24 * 60 * 60 * 1000) / chartWidth;
        const msDelta = deltaX * msPerPixel;

        setCurrentEndDate(prevDate => {
            const newDate = new Date(prevDate.getTime() - msDelta);
            const now = new Date();
            const oneYearAgo = new Date();
            oneYearAgo.setFullYear(oneYearAgo.getFullYear() - 1);
            
            // Apply boundaries
            if (newDate > now) return now;
            if (newDate < oneYearAgo) return oneYearAgo;
            return newDate;
        });
        
        // Force stats to update after dragging
        processStats();
    }, [viewMode, processStats]);

    const handleMouseUp = useCallback(() => {
        isDraggingRef.current = false;
        if (chartContainerRef.current) {
            chartContainerRef.current.style.cursor = 'grab';
        }
    }, []);

    // Add effect to handle mouse up outside the chart
    useEffect(() => {
        const handleGlobalMouseUp = () => {
            if (isDraggingRef.current) {
                handleMouseUp();
            }
        };

        window.addEventListener('mouseup', handleGlobalMouseUp);
        return () => window.removeEventListener('mouseup', handleGlobalMouseUp);
    }, [handleMouseUp]);

    useEffect(() => {
        if (chartContainerRef.current) {
            const resizeObserver = new ResizeObserver(() => {
                // Force recharts to recalculate dimensions
                window.dispatchEvent(new Event('resize'));
            });
            
            resizeObserver.observe(chartContainerRef.current);
            
            return () => {
                resizeObserver.disconnect();
            };
        }
    }, []);

    // Add function to extract unique codes from stored stats
    const getCodesFromStats = useCallback(() => {
        const storedStats = loadStoredStats();
        const uniqueCodes = new Set();
        
        storedStats.forEach(stat => {
            if (stat.code_id && stat.code_value) {
                uniqueCodes.add(JSON.stringify({
                    id: stat.code_id,
                    name: stat.code_value
                }));
            }
        });

        return Array.from(uniqueCodes)
            .map(code => JSON.parse(code))
            .sort((a, b) => a.name.localeCompare(b.name));
    }, [loadStoredStats]);

    // Add touch event handlers
    const handleTouchStart = useCallback((e) => {
        isDraggingRef.current = true;
        lastMouseXRef.current = e.touches[0].clientX;
    }, []);

    const handleTouchMove = useCallback((e) => {
        if (!isDraggingRef.current) return;
        e.preventDefault();
        
        const touch = e.touches[0];
        const deltaX = touch.clientX - lastMouseXRef.current;
        lastMouseXRef.current = touch.clientX;

        // Reuse the same logic as mouse move
        if (chartContainerRef.current) {
            const chartWidth = chartContainerRef.current.offsetWidth;
            const timeRange = viewMode === 'week' ? 7 : 30;
            const msPerPixel = (timeRange * 24 * 60 * 60 * 1000) / chartWidth;
            const msDelta = deltaX * msPerPixel;

            setCurrentEndDate(prevDate => {
                const newDate = new Date(prevDate.getTime() - msDelta);
                const now = new Date();
                const oneYearAgo = new Date();
                oneYearAgo.setFullYear(oneYearAgo.getFullYear() - 1);
                
                if (newDate > now) return now;
                if (newDate < oneYearAgo) return oneYearAgo;
                return newDate;
            });
        }
    }, [viewMode]);

    const handleTouchEnd = useCallback(() => {
        isDraggingRef.current = false;
    }, []);

    // Update isToday check in the render
    const isCurrentlyToday = useMemo(() => {
        return isToday(currentEndDate);
    }, [currentEndDate]);

    return (
        <Paper 
            elevation={0}
            sx={{ 
               // p: 3, 
                height: '100%',
                backgroundColor: theme.palette.background.default 
            }}
        >
            {/* Header Section */}
            <Box sx={{ 
                display: 'flex', 
                justifyContent: 'space-between', 
                alignItems: 'center',
                mb: {xs: 1, sm: 1},
                flexDirection: { xs: 'column', sm: 'row' },  // Stack vertically on mobile
                gap: { xs: 0, sm: 0 },  // Add gap for mobile stacked layout
                borderBottom: { xs: 'none', sm: `1px solid ${theme.palette.divider}` },
                pb: { xs: 0, sm: 0 }  // Add padding bottom on mobile
            }}>
                <Typography variant="h5">Dashboard</Typography>
                <Box sx={{ 
                    display: 'flex', 
                    gap: 2, 
                    alignItems: 'center',
                    width: { xs: '100%', sm: 'auto' },  // Full width on mobile
                    justifyContent: { xs: 'space-between', sm: 'flex-end' }  // Spread on mobile
                }}>
                    {lastRefreshRef.current && (
                        <Typography variant="caption" color="textSecondary">
                            Last updated: {lastRefreshRef.current.toLocaleString('en-GB', {
                                day: '2-digit',
                                month: 'short',
                                hour: '2-digit',
                                minute: '2-digit',
                                second: '2-digit'
                            })}
                        </Typography>
                    )}
                    <Button 
                        startIcon={<RefreshIcon />}
                        onClick={() => debouncedFetchStats(true)}
                        disabled={isManualRefreshing}
                        sx={{
                            height: { xs: 28, sm: 40 },
                        }}
                    >
                        Refresh
                    </Button>
                </Box>
            </Box>

            {/* Controls Section */}
            <Box sx={{ 
                display: 'flex', 
                gap: { xs: 1, sm: 2 },
                mb: { xs: 1, sm: 1 },
                flexWrap: 'wrap',
                alignItems: 'center',
                flexDirection: { xs: 'column', sm: 'row' },
                justifyContent: { xs: 'flex-start', sm: 'space-between' },  // Evenly distribute in desktop
                '& > *': {
                    width: { xs: '100%', sm: 'auto' }
                }
            }}>
                <FormControl 
                    sx={{ 
                        minWidth: { xs: '100%', sm: 120 },
                        '& .MuiInputBase-root': {
                            height: { xs: 28, sm: 40 },
                        },
                        '& .MuiOutlinedInput-notchedOutline': {
                            borderRadius: 1,
                        }
                    }}
                >
                    <Select
                        id="code-select"
                        value={selectedCode}
                        onChange={(e) => setSelectedCode(e.target.value)}
                        sx={{
                            '& .MuiSelect-select': {
                                padding: '8px 14px',
                            }
                        }}
                    >
                        <MenuItem value="all">All Codes</MenuItem>
                        {getCodesFromStats().map((code) => (
                            <MenuItem key={code.id} value={code.id}>
                                {code.name}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>

                <ToggleButtonGroup
                    value={viewMode}
                    exclusive
                    onChange={(e, newValue) => newValue && setViewMode(newValue)}
                    sx={{
                        height: { xs: 28, sm: 40 },
                        width: { xs: '100%', sm: 'auto' },
                        '& .MuiToggleButton-root': {
                            py: 0.5,
                            fontSize: '0.875rem',
                            flex: { xs: 1, sm: 'initial' }
                        }
                    }}
                >
                    <ToggleButton value="week">Week</ToggleButton>
                    <ToggleButton value="month">Month</ToggleButton>
                </ToggleButtonGroup>

                <Box sx={{ 
                    display: 'flex', 
                    alignItems: 'center',
                    height: { xs: 28, sm: 40 },
                    gap: 1,
                    width: { xs: '100%', sm: 'auto' },
                    justifyContent: { xs: 'space-between', sm: 'center' }  // Center navigation controls
                }}>
                    <IconButton
                        onClick={() => handleNavigate('back')}
                        size="small"
                    >
                        <NavigateBeforeIcon />
                    </IconButton>

                    <Button
                        startIcon={<TodayIcon />}
                        onClick={handleToday}
                        disabled={isCurrentlyToday}
                        sx={{ 
                            flex: { xs: 1, sm: 'initial' }
                        }}
                    >
                        Today
                    </Button>

                    <IconButton
                        onClick={() => handleNavigate('forward')}
                        disabled={!canNavigateForward}
                        size="small"
                    >
                        <NavigateNextIcon />
                    </IconButton>
                </Box>
            </Box>

            {/* Add error display */}
            {error && (
                <Typography color="error" sx={{ mb: 2 }}>
                    {error}
                </Typography>
            )}

            {/* Chart Section */}
            <Box 
                ref={chartContainerRef}
                sx={{ 
                    height: { xs: 120, sm: 200 }, 
                    position: 'relative',
                    cursor: 'grab',
                    userSelect: 'none',
                    touchAction: 'none',
                    width: '100%',
                    '& .recharts-wrapper': {
                        width: '100% !important',
                        cursor: 'inherit'
                    },
                    '& .recharts-surface': {
                        width: '100% !important',
                        cursor: 'inherit'
                    }
                }}
                onMouseDown={handleMouseDown}
                onMouseMove={handleMouseMove}
                onMouseUp={handleMouseUp}
                onMouseLeave={handleMouseUp}
                onTouchStart={handleTouchStart}
                onTouchMove={handleTouchMove}
                onTouchEnd={handleTouchEnd}
            >
                {isManualRefreshing && (
                    <Box sx={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        right: 0,
                        bottom: 0,
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        backgroundColor: 'rgba(255,255,255,0.7)',
                        zIndex: 1
                    }}>
                        <CircularProgress />
                    </Box>
                )}
                <ResponsiveContainer width="100%" height="100%">
                    <BarChart
                        data={processedStats}
                        margin={{ top: 20, right: 20, left: 20, bottom: 5 }}
                        layout="horizontal"
                        align="center"
                    >
                        <CartesianGrid 
                            strokeDasharray="3 3" 
                            opacity={0}
                            horizontal={true}
                            vertical={false}
                        />
                        <XAxis 
                            dataKey="date" 
                            tickFormatter={(date) => {
                                const d = new Date(date);
                                return d.toLocaleDateString('en-GB', { 
                                    day: '2-digit',
                                    month: 'short'
                                });
                            }}
                            tick={({ x, y, payload, ...props }) => {
                                const isWeekendDay = isWeekend(payload.value);
                                return (
                                    <g transform={`translate(${x},${y})`}>
                                        <text
                                            x={0}
                                            y={0}
                                            dy={16}
                                            textAnchor="middle"
                                            fill={isWeekendDay ? theme.palette.error.main : theme.palette.text.primary}
                                        >
                                            {new Date(payload.value).toLocaleDateString('en-GB', { 
                                                day: '2-digit',
                                                month: 'short'
                                            })}
                                        </text>
                                    </g>
                                );
                            }}
                            tickLine={false}
                            axisLine={true}
                            offset={0}
                        />
                        <YAxis 
                            tickLine={false}
                            tick={false}
                            axisLine={false}
                            width={0}
                        />
                        <Bar 
                            dataKey="clicks" 
                            fill={theme.palette.primary.main}
                            radius={[4, 4, 0, 0]}
                            maxBarSize={100}
                            animationDuration={300}
                            label={{
                                position: 'top',
                                fill: theme.palette.text.primary,
                                fontSize: 12,
                                formatter: (value) => value > 0 ? value : ''
                            }}
                        />
                    </BarChart>
                </ResponsiveContainer>
            </Box>
        </Paper>
    );
};

export default CodeManagerDashboard; 