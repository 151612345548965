import axios from 'axios';
import config from '../config';

const codeManagerApi = axios.create({
    baseURL: config.codeManagerApiUrl,
    headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json'
    },
    withCredentials: true
});

// Add request interceptor for authentication
codeManagerApi.interceptors.request.use(async request => {
    try {
        const token = localStorage.getItem('authToken');
        if (token) {
            request.headers['Authorization'] = `Bearer ${token}`;
        }
    } catch (error) {
        console.error('CodeManager API - Request error:', error);
    }
    return request;
});

// Response interceptor for error handling
codeManagerApi.interceptors.response.use(
    response => {
        console.log('CodeManager API - Success:', {
            url: response.config.url,
            status: response.status
        });
        return response;
    },
    error => {
        console.error('CodeManager API - Error:', {
            url: error.config?.url,
            status: error.response?.status,
            message: error.response?.data?.error || error.message
        });
        return Promise.reject(error);
    }
);

// Get available paid codes for selected period
export const getPaidCodes = async (valid_from, valid_until) => {
    try {
        const response = await codeManagerApi.get('/api/codes/paid', {
            params: {
                valid_from,
                valid_until
            }
        });
        return response.data;
    } catch (error) {
        console.error('Error fetching paid codes:', error);
        throw error;
    }
};

// Check availability only for complete codes
export const checkCodeAvailability = async ({ code, valid_from, valid_until, exclude_code_id }) => {
    try {
        const response = await codeManagerApi.post('/api/codes/check-availability', {
            code,
            valid_from,
            valid_until,
            exclude_code_id
        });
        return response.data;
    } catch (error) {
        console.error('Error checking code availability:', error);
        throw error;
    }
};

// Get owner's codes
export const getOwnerCodes = async () => {
    try {
        const response = await codeManagerApi.get('/api/codes/owner');
        return response.data;
    } catch (error) {
        console.error('Error fetching owner codes:', error);
        throw error;
    }
};

// Create new code
export const createCode = async (codeData) => {
    try {
        const formattedData = {
            ...codeData,
            valid_from: codeData.valid_from ? new Date(codeData.valid_from).toISOString() : null,
            valid_until: codeData.valid_until ? new Date(codeData.valid_until).toISOString() : null
        };
        
        const response = await codeManagerApi.post('/api/codes', formattedData);
        return response.data;
    } catch (error) {
        console.error('Failed to create code:', error);
        throw error;
    }
};

// Update existing code
export const updateCode = async (codeId, codeData) => {
    try {
        const formattedData = {
            ...codeData,
            valid_from: codeData.valid_from ? new Date(codeData.valid_from).toISOString() : null,
            valid_until: codeData.valid_until ? new Date(codeData.valid_until).toISOString() : null
        };
        
        const response = await codeManagerApi.put(`/api/codes/${codeId}`, formattedData);
        return response.data;
    } catch (error) {
        console.error('Failed to update code:', error);
        throw error;
    }
};

// Delete code
export const deleteCode = async (codeId) => {
    try {
        await codeManagerApi.delete(`/api/codes/${codeId}`);
        return true;
    } catch (error) {
        console.error('Failed to delete code:', error);
        throw error;
    }
};

// Validate URL
export const validateUrl = async (url) => {
    try {
        const response = await codeManagerApi.post('/api/codes/validate-url', { url });
        return response.data;
    } catch (error) {
        console.error('Error validating URL:', error);
        throw error;
    }
};

// Add content moderation check
export const checkContentModeration = async (content) => {
    try {
        const response = await codeManagerApi.post('/api/codes/check-content', content);
        return response.data;
    } catch (error) {
        console.error('Error checking content:', error);
        throw error;
    }
};

// Add image upload function
export const uploadImage = async (imageFile, existingUrl) => {
    const formData = new FormData();
    formData.append('image', imageFile);
    
    // If we have existing URL, extract the path
    if (existingUrl) {
        const path = existingUrl.split('.com/')[1];
        formData.append('existing_path', path);
    }

    try {
        const response = await codeManagerApi.post('/api/upload/image', formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
        return response.data.imageUrl;
    } catch (error) {
        console.error('Error uploading image:', error);
        throw error;
    }
};

// Get click statistics
export const getClickStats = async ({ endDate, fromDate }) => {
    try {
        const params = {
            end_date: endDate.toISOString(),
            timezone: Intl.DateTimeFormat().resolvedOptions().timeZone
        };
        
        if (fromDate) {
            params.from_date = fromDate.toISOString();
        }
        
        const response = await codeManagerApi.get('/api/stats/clicks', { params });
        return response.data;
    } catch (error) {
        console.error('Error fetching click stats:', error);
        throw error;
    }
};

export default codeManagerApi; 