import React, { useState } from 'react';
import { 
    AppBar, 
    Toolbar, 
    Box, 
    Button, 
    Typography,
    IconButton,
    Menu,
    MenuItem,
    useTheme,
    useMediaQuery 
} from '@mui/material';
import { Link } from 'react-router-dom';
import AppleIcon from '@mui/icons-material/Apple';
import AndroidIcon from '@mui/icons-material/Android';
import InfoIcon from '@mui/icons-material/Info';
import MenuIcon from '@mui/icons-material/Menu';
import AppLogo from './AppLogo';

function Header() {
    const [mobileMenuAnchor, setMobileMenuAnchor] = useState(null);
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const handleMobileMenuOpen = (event) => {
        setMobileMenuAnchor(event.currentTarget);
    };

    const handleMobileMenuClose = () => {
        setMobileMenuAnchor(null);
    };

    const DesktopMenu = () => (
        <Box sx={{ 
            display: 'flex', 
            alignItems: 'center',
            gap: 2
        }}>
            <Button
                sx={{ 
                    textTransform: 'none',
                    color: theme.palette.header.textColor
                }}
                startIcon={<AppleIcon />}
                component="a"
                href="https://apps.apple.com/app/get-code/id123456789"
                target="_blank"
            >
                App Store
            </Button>
            
            <Button
                sx={{ 
                    textTransform: 'none',
                    color: theme.palette.header.textColor
                }}
                startIcon={<AndroidIcon />}
                component="a"
                href="https://play.google.com/store/apps/details?id=com.getcode.app"
                target="_blank"
            >
                Google Play
            </Button>

            <Button
                sx={{ 
                    textTransform: 'none',
                    color: theme.palette.header.textColor
                }}
                startIcon={<InfoIcon />}
                component={Link}
                to="/about"
            >
                About
            </Button>
        </Box>
    );

    const MobileMenu = () => (
        <>
            <IconButton
                color="inherit"
                onClick={handleMobileMenuOpen}
                sx={{ ml: 'auto' }}
            >
                <MenuIcon />
            </IconButton>
            
            <Menu
                anchorEl={mobileMenuAnchor}
                open={Boolean(mobileMenuAnchor)}
                onClose={handleMobileMenuClose}
                onClick={handleMobileMenuClose}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                PaperProps={{
                    style: {
                        marginLeft: 'auto',
                        marginRight: '16px',
                        width: '180px'
                    }
                }}
                sx={{
                    '& .MuiPaper-root': {
                        backgroundColor: theme.palette.background.paper,
                        borderRadius: 1,
                        marginTop: '8px',
                        boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.5)',
                        '& .MuiList-root': {
                            padding: '8px 0',
                        },
                        '& .MuiMenuItem-root': {
                            padding: '12px 24px',
                            color: theme.palette.text.primary,
                            '&:hover': {
                                backgroundColor: theme.palette.action.hover
                            }
                        }
                    },
                    '& .MuiMenu-paper': {
                        right: 0,
                        left: 'auto !important',
                    }
                }}
            >
                <MenuItem 
                    component="a"
                    href="https://apps.apple.com/app/get-code/id123456789"
                    target="_blank"
                    sx={{ 
                        justifyContent: 'flex-end',
                        gap: 1,
                        '& .MuiSvgIcon-root': {
                            color: theme.palette.text.secondary
                        }
                    }}
                >
                    App Store <AppleIcon />
                </MenuItem>
                <MenuItem 
                    component="a"
                    href="https://play.google.com/store/apps/details?id=com.getcode.app"
                    target="_blank"
                    sx={{ 
                        justifyContent: 'flex-end',
                        gap: 1,
                        '& .MuiSvgIcon-root': {
                            color: theme.palette.text.secondary
                        }
                    }}
                >
                    Google Play <AndroidIcon />
                </MenuItem>
                <MenuItem 
                    component={Link}
                    to="/about"
                    sx={{ 
                        justifyContent: 'flex-end',
                        gap: 1,
                        '& .MuiSvgIcon-root': {
                            color: theme.palette.text.secondary
                        }
                    }}
                >
                    About <InfoIcon />
                </MenuItem>
            </Menu>
        </>
    );

    return (
        <AppBar 
            position="fixed"
            elevation={0}
            sx={{ 
                backgroundColor: theme.palette.background.default,
              //  borderBottom: `1px solid ${theme.palette.separator.background}`,
                color: theme.palette.header.textColor
            }}
        >
            <Toolbar sx={{ px: { xs: 1, sm: 2 } }}>
                {/* Left side - Logo and App Name */}
                <Box sx={{ 
                    display: 'flex', 
                    alignItems: 'center',
                    flexGrow: 1 
                }}>
                    <AppLogo size={32} />
                    <Typography 
                        variant="h6" 
                        component="div"
                        sx={{ 
                            ml: 2,
                            fontSize: { xs: '1rem', sm: '1.25rem' }
                        }}
                    >
                        Get-Code
                    </Typography>
                </Box>

                {/* Right side - Navigation Links */}
                {isMobile ? <MobileMenu /> : <DesktopMenu />}
            </Toolbar>
        </AppBar>
    );
}

export default Header; 