import React, { useState } from 'react';
import { 
    Container, 
    Paper, 
    Typography, 
    TextField, 
    Button, 
    Box,
    IconButton,
    Alert,
    Snackbar
} from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate } from 'react-router-dom';
import { signUpCodeOwner, verifyCodeOwnerOTP, checkEmailExists, loginCodeOwner, requestPasswordReset, resetPassword } from '../services/api';

function AuthPage() {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [otp, setOtp] = useState('');
    const [isNewUser, setIsNewUser] = useState(false);
    const [showOtpField, setShowOtpField] = useState(false);
    const [error, setError] = useState('');
    const [successMessage, setSuccessMessage] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [isResettingPassword, setIsResettingPassword] = useState(false);
    const [newPassword, setNewPassword] = useState('');
    const [confirmNewPassword, setConfirmNewPassword] = useState('');
    const [emailExists, setEmailExists] = useState(false);
    const navigate = useNavigate();

    // Handle email check when user finishes typing email
    const handleEmailBlur = async (e) => {
        if (email) {
            const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            if (emailRegex.test(email)) {
                setIsLoading(true);
                try {
                    const exists = await checkEmailExists(email);
                    setEmailExists(exists);
                    setIsNewUser(!exists);
                    if (!exists) {
                        setSuccessMessage('Welcome! Please create your account.');
                    } else {
                        setSuccessMessage('Welcome back! Please enter your password.');
                    }
                } catch (err) {
                    console.error('Error checking email:', err);
                    setEmailExists(false);
                } finally {
                    setIsLoading(false);
                }
            }
        }
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        setError('');
        setIsLoading(true);
        
        if (isNewUser) {
            // Email validation
            const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            if (!emailRegex.test(email)) {
                setError('Please enter a valid email address');
                setIsLoading(false);
                return;
            }

            // Password validation
            if (password.length < 6) {
                setError('Password must be at least 6 characters long');
                setIsLoading(false);
                return;
            }

            if (password !== confirmPassword) {
                setError('Passwords do not match');
                setIsLoading(false);
                return;
            }
            
            try {
                await signUpCodeOwner(email, password);
                setSuccessMessage('OTP sent to your email. Please check and verify.');
                setShowOtpField(true);
            } catch (err) {
                setError(err.response?.data?.error || 'Error during signup');
            }
        } else {
            // Handle login
            try {
                await loginCodeOwner(email, password);
                setSuccessMessage('Login successful!');
                navigate('/owner');
            } catch (err) {
                setError(err.response?.data?.error || 'Invalid email or password');
                setIsLoading(false);
            }
        }
        if (isNewUser) {
            setIsLoading(false);
        }
    };

    const handleVerifyOTP = async (event) => {
        event.preventDefault();
        setError('');
        setIsLoading(true);
        
        try {
            await verifyCodeOwnerOTP(email, otp);
            setSuccessMessage('Email verified successfully!');
            navigate('/owner');
        } catch (err) {
            setError(err.response?.data?.message || 'Invalid OTP');
        } finally {
            setIsLoading(false);
        }
    };

    const handleForgotPassword = async () => {
        setError('');
        setIsLoading(true);
        try {
            await requestPasswordReset(email);
            setSuccessMessage('Password reset code sent to your email');
            setIsResettingPassword(true);
            setShowOtpField(true);
        } catch (err) {
            setError(err.response?.data?.error || 'Failed to request password reset');
        } finally {
            setIsLoading(false);
        }
    };

    const handleResetPassword = async (event) => {
        event.preventDefault();
        setError('');
        setIsLoading(true);

        if (newPassword !== confirmNewPassword) {
            setError('Passwords do not match');
            setIsLoading(false);
            return;
        }

        try {
            await resetPassword(email, otp, newPassword);
            setSuccessMessage('Password reset successful! Redirecting to dashboard...');
            setTimeout(() => navigate('/owner'), 2000);
        } catch (err) {
            setError(err.response?.data?.error || 'Failed to reset password');
            setIsLoading(false);
        }
    };

    return (
        <Container maxWidth="sm" sx={{ mt: 4 }}>
            <Box sx={{ mb: 4 }}>
                <IconButton onClick={() => navigate('/')} sx={{ mr: 2 }}>
                    <ArrowBackIcon />
                </IconButton>
                <Typography variant="h4" component="h1" display="inline">
                    {isNewUser ? 'Create Account' : 'Log In'}
                </Typography>
            </Box>

            <Paper sx={{ p: 3 }}>
                {error && (
                    <Alert severity="error" sx={{ mb: 2 }}>
                        {error}
                    </Alert>
                )}
                
                <Snackbar
                    open={!!successMessage}
                    autoHideDuration={6000}
                    onClose={() => setSuccessMessage('')}
                >
                    <Alert severity="success" sx={{ width: '100%' }}>
                        {successMessage}
                    </Alert>
                </Snackbar>

                <form onSubmit={
                    isResettingPassword ? handleResetPassword :
                    showOtpField ? handleVerifyOTP : 
                    handleSubmit
                }>
                    <TextField
                        fullWidth
                        label="Email"
                        type="email"
                        margin="normal"
                        required
                        value={email}
                        onBlur={handleEmailBlur}
                        onChange={(e) => setEmail(e.target.value)}
                    />
                    {email && !showOtpField && !isResettingPassword && (
                        <Typography 
                            variant="body2" 
                            color="text.secondary" 
                            sx={{ mt: 1, mb: 1, pl: 1 }}
                        >
                            {isNewUser 
                                ? "Nice to meet you, please create and confirm password" 
                                : "Welcome back, please enter password to log in"}
                        </Typography>
                    )}
                    
                    {!isResettingPassword && !showOtpField && (
                        <>
                            <TextField
                                fullWidth
                                label="Password"
                                type="password"
                                margin="normal"
                                required
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                            />
                            {isNewUser && (
                                <TextField
                                    fullWidth
                                    label="Confirm Password"
                                    type="password"
                                    margin="normal"
                                    required
                                    value={confirmPassword}
                                    onChange={(e) => setConfirmPassword(e.target.value)}
                                />
                            )}
                        </>
                    )}

                    {showOtpField && (
                        <TextField
                            fullWidth
                            label="Enter OTP"
                            type="text"
                            margin="normal"
                            required
                            value={otp}
                            onChange={(e) => setOtp(e.target.value)}
                            placeholder="Enter the 6-digit code sent to your email"
                        />
                    )}

                    {isResettingPassword && showOtpField && otp && (
                        <>
                            <TextField
                                fullWidth
                                label="New Password"
                                type="password"
                                margin="normal"
                                required
                                value={newPassword}
                                onChange={(e) => setNewPassword(e.target.value)}
                            />
                            <TextField
                                fullWidth
                                label="Confirm New Password"
                                type="password"
                                margin="normal"
                                required
                                value={confirmNewPassword}
                                onChange={(e) => setConfirmNewPassword(e.target.value)}
                            />
                        </>
                    )}

                    <Button
                        type="submit"
                        variant="contained"
                        fullWidth
                        sx={{ mt: 3 }}
                        disabled={isLoading}
                    >
                        {isLoading ? 'Please wait...' : 
                            (isResettingPassword ? 'Reset Password' :
                            showOtpField ? 'Verify OTP' : 
                            (isNewUser ? 'Create Account' : 'Log In'))}
                    </Button>

                    {emailExists && !showOtpField && !isResettingPassword && (
                        <Button
                            onClick={handleForgotPassword}
                            color="primary"
                            variant="text"
                            sx={{ mt: 1 }}
                            fullWidth
                            disabled={isLoading}
                        >
                            Forgot Password?
                        </Button>
                    )}
                </form>
            </Paper>
        </Container>
    );
}

export default AuthPage; 