import React, { useState, useEffect, useRef, useCallback } from 'react';
import { 
    Box, 
    TextField, 
    Button, 
    Typography,
    List,
    ListItem,
    Paper,
    CircularProgress,
    useTheme,
    Divider
} from '@mui/material';
import { searchCodes, getCode, logActivity } from '../services/api';
import CodeHistory from './CodeHistory';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

const UserConsole = () => {
    const [code, setCode] = useState('');
    const [suggestions, setSuggestions] = useState([]);
    const [isInputFocused, setIsInputFocused] = useState(false);
    const [selectedCode, setSelectedCode] = useState(null);
    const [isDetailVisible, setIsDetailVisible] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);
    const [isHistoryExpanded, setIsHistoryExpanded] = useState(true);
    const [isHistoryEmpty, setIsHistoryEmpty] = useState(true);
    const detailRef = useRef(null);
    const codeInputRef = useRef(null);
    const theme = useTheme();
    const historyHeaderRef = useRef(null);

    useEffect(() => {
        const history = JSON.parse(localStorage.getItem('codeHistory') || '[]');
        setIsHistoryEmpty(history.length === 0);
        setIsHistoryExpanded(!history.length === 0);  // Auto-collapse if empty
    }, []);

    useEffect(() => {
        const handleHistoryUpdate = () => {
            const history = JSON.parse(localStorage.getItem('codeHistory') || '[]');
            setIsHistoryEmpty(history.length === 0);
        };

        window.addEventListener('historyUpdated', handleHistoryUpdate);
        return () => {
            window.removeEventListener('historyUpdated', handleHistoryUpdate);
        };
    }, []);

    useEffect(() => {
        function handleClickOutside(event) {
            // Check if click was on history toggle elements
            const isHistoryHeaderClick = historyHeaderRef.current && 
                (historyHeaderRef.current.contains(event.target) ||
                 event.target.closest('[data-history-toggle]')); // For any child elements

            // Only handle click if it's not on detail card and not on history toggle
            if (detailRef.current && 
                !detailRef.current.contains(event.target) && 
                !isHistoryHeaderClick) {
                if (selectedCode || error) {
                    handleDetailClose();
                }
            }
        }

        if (selectedCode || error) {
            document.addEventListener('mousedown', handleClickOutside);
            return () => {
                document.removeEventListener('mousedown', handleClickOutside);
            };
        }
    }, [selectedCode, error]);

    const updateHistory = useCallback((code) => {
        if (!code) return;
        
        const history = JSON.parse(localStorage.getItem('codeHistory') || '[]');
        // Remove if exists (to avoid duplicates)
        const filteredHistory = history.filter(h => h.code !== code.code);
        // Add to beginning
        filteredHistory.unshift(code);
        localStorage.setItem('codeHistory', JSON.stringify(filteredHistory));
        window.dispatchEvent(new Event('historyUpdated'));
    }, []);

    const handleDetailClose = useCallback(() => {
        if (selectedCode) {
            updateHistory(selectedCode);
        }
        setIsDetailVisible(false);
        setSelectedCode(null);
        setError(null);
        setIsLoading(false);
    }, [selectedCode, updateHistory]);

    useEffect(() => {
        const handleBeforeUnload = () => {
            if (selectedCode) {
                updateHistory(selectedCode);
            }
        };

        window.addEventListener('beforeunload', handleBeforeUnload);
        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
        };
    }, [selectedCode, updateHistory]);

    const handleCodeChange = async (e) => {
        const value = e.target.value.toUpperCase();
        if (value.length <= 20) {
            setCode(value);

            if (isDetailVisible) {
                handleDetailClose();
            }

            if (value.length >= 1) {
                try {
                    const results = await searchCodes(value);
                    const sortedResults = results.sort((a, b) => a.code.localeCompare(b.code));
                    setSuggestions(sortedResults);
                } catch (error) {
                    console.error('Error fetching suggestions:', error);
                    setSuggestions([]);
                }
            } else {
                setSuggestions([]);
            }
        }
    };

    const handleInputFocus = () => {
        setIsInputFocused(true);
        if (code.length >= 1 && suggestions.length === 0) {
            handleCodeChange({ target: { value: code } });
        }
    };

    const handleInputBlur = () => {
        setTimeout(() => {
            setIsInputFocused(false);
        }, 200);
    };

    const handleCodeSelect = async (codeValue, shouldShowInInput = false, suggestionData = null) => {
        try {
            // 1. Show full code in input immediately
            setCode(codeValue.toUpperCase());
            
            // 2. UI State Updates
            setSelectedCode(null);
            setError(null);
            setIsLoading(true);
            setIsInputFocused(false);
            setSuggestions([]);

            // 3. Get code data
            const codeData = await getCode(codeValue);
            
            // 4. If we have suggestion data, use its fresh image URL
            if (suggestionData && suggestionData.imageUrl) {
                codeData.imageUrl = suggestionData.imageUrl;
            }
            
            // 5. Update UI and redirect
            setSelectedCode(codeData);
            setIsLoading(false);

            // Log activity first
            try {
                await logActivity('visit_code', codeData.code, codeData.code_id, codeData.owner_id);
            } catch (error) {
                console.error('Error logging activity:', error);
            }

            // Create and click a real anchor element instead of using window.open
            const link = document.createElement('a');
            link.href = codeData.url;
            link.target = '_blank';
            link.rel = 'noopener noreferrer';
            // Add the link to the document body
            document.body.appendChild(link);
            // Trigger click
            link.click();
            // Clean up
            document.body.removeChild(link);

            // Update history
            updateHistory(codeData);

            // Clear input after delay
            setTimeout(() => {
                setCode('');
                setSelectedCode(null);
            }, 2000);

        } catch (error) {
            console.error('Error fetching code:', error);
            setSelectedCode(null);
            setError(`Code "${codeValue}" is not found. Create it as a Code Owner.`);
            setIsLoading(false);
            setCode('');
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (code) {
            const enteredCode = code.trim();
            handleCodeSelect(enteredCode);
        }
    };

    const handleHistoryItemClick = useCallback((historyCode) => {
        // If there's a detailed code visible, process its cleanup
        if (selectedCode || error) {
            handleDetailClose();
        }
    }, [selectedCode, error, handleDetailClose]);

    // New useEffect to handle logging after render
    useEffect(() => {
        const logViewActivity = async () => {
            if (selectedCode) {
                try {
                    await logActivity('view_code', selectedCode.code, selectedCode.code_id);
                } catch (error) {
                    console.error('Error logging activity:', error);
                }
            }
        };

        logViewActivity();
    }, [selectedCode]); // Runs when selectedCode changes

    return (
        <Box sx={{ 
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            px: 0  // Changed from px: { xs: 1.5, sm: 2, md: 3 }
        }}>
            {/* Static top content */}
            <Box sx={{ 
                flex: 'none',
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'left'
               // alignItems: 'center'
            }}> 
                <Typography 
                    variant="h4"
                    noWrap
                    align="left"
                    sx={{ 
                        color: theme.palette.text.primary,
                        fontSize: { xs: '1.0rem', sm: '2.0rem' },
                        mt: { xs: 2, sm: 3 },
                        mb: { xs: 2, sm: 3 },
                        maxWidth: '600px',
                        width: '100%',
                        display: 'block',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        fontWeight: 700
                    }}
                >
                    Seen or Heard a Code? Check it out!
                </Typography>

                {/* <Typography 
                    variant="body1" 
                    align="center"
                    sx={{
                        maxWidth: '600px',
                        mb: 3,
                        fontSize: { xs: '0.9rem', sm: '1.1rem' },
                        color: 'text.secondary',
                        display: '-webkit-box',
                        WebkitLineClamp: {xs: 3, sm: 2},
                        WebkitBoxOrient: 'vertical',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis'
                    }}
                >
                    Just enter the code you've spotted on ads, billboards, 
                    or heard in media to instantly connect with the product 
                    or service you're interested in.
                </Typography> */}

                {/* Search Form - wider input box */}
                <Box 
                    component="form" 
                    onSubmit={handleSubmit}
                    sx={{
                        display: 'flex',
                        gap: 2,  // Increased gap between input and button
                        width: '100%',
                        maxWidth: '500px',
                        mb: 0,  // Reduced from mb: 4
                        position: 'relative'
                    }}
                >
                    <TextField
                        value={code}
                        onChange={handleCodeChange}
                        onFocus={handleInputFocus}
                        onBlur={handleInputBlur}
                        placeholder="Enter code here (e.g., SG2025)"
                        fullWidth
                        autoComplete="off"
                        inputProps={{
                            autoCorrect: 'off',
                            autoCapitalize: 'off',
                            spellCheck: 'false',
                            style: {
                                fontSize: { xs: '0.875rem', sm: '1rem' }
                            }
                        }}
                        sx={{
                            '& .MuiOutlinedInput-root': {
                                height: '56px',
                                backgroundColor: theme.palette.input.background,
                                '&:hover': {
                                    backgroundColor: theme.palette.input.activeBackground
                                },
                                '&.Mui-focused': {
                                    backgroundColor: theme.palette.input.activeBackground
                                },
                                '& input': {
                                    fontSize: { xs: '0.875rem', sm: '1rem' }
                                }
                            }
                        }}


                        // sx={{
                        //     flex: 1,
                        //     '& .MuiOutlinedInput-root': {
                        //         height: '56px',
                        //         backgroundColor: theme.palette.input.background,
                        //         fontSize: '1.1rem',
                        //         borderRadius: 1,
                        //         border: 'none',
                        //         transition: 'background-color 0.2s ease',
                        //         '& fieldset': {
                        //             border: 'none'
                        //         },
                        //         '&:hover, &.Mui-focused': {
                        //             backgroundColor: theme.palette.input.activeBackground,
                        //         }
                        //     }
                        // }}
                    />
                    <Button
                        type="submit"
                        sx={{
                            minWidth: 'auto',
                            width: 'auto',
                            height: '56px',
                            padding: '0 4px',
                            backgroundColor: 'transparent',
                            color: theme.palette.button.transparent.color,
                            fontSize: '1.1rem',
                            fontWeight: 500,
                            '&:hover': {
                                backgroundColor: 'transparent',
                                color: theme.palette.button.transparent.hoverColor
                            },
                            '&:active': {
                                backgroundColor: 'transparent',
                                color: theme.palette.button.transparent.activeColor
                            },
                            '&:disabled': {
                                backgroundColor: 'transparent',
                                color: theme.palette.button.transparent.disabledColor
                            }
                        }}
                        disabled={!code.trim()}
                    >
                        GO
                    </Button>

                    {/* Suggestions dropdown */}
                    {isInputFocused && suggestions.length > 0 && (
                        <Paper
                            sx={{
                                position: 'absolute',
                                top: '100%',
                                left: 0,
                                right: 0,
                                mt: 1,
                                maxHeight: '320px',
                                overflowY: 'auto',
                                overflowX: 'hidden', // Prevent horizontal scrolling
                                backgroundColor: theme.palette.background.paper,
                                borderRadius: 1,
                                border: `1px solid ${theme.palette.separator.background}`,
                                boxShadow: '0px 5px 15px rgba(0, 0, 0, 0.2)',
                                zIndex: 1200,
                                '&::-webkit-scrollbar': {
                                    width: '8px',
                                },
                                '&::-webkit-scrollbar-track': {
                                    background: 'transparent',
                                },
                                '&::-webkit-scrollbar-thumb': {
                                    background: theme.palette.divider,
                                    borderRadius: '4px',
                                },
                                '&::-webkit-scrollbar-thumb:hover': {
                                    background: theme.palette.action.hover,
                                }
                            }}
                        >
                            <List sx={{ p: 0 }}>
                                {suggestions.map((suggestion) => (
                                    <ListItem
                                        key={suggestion.code}
                                        component="a"
                                        href={suggestion.url}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        onClick={async (e) => {
                                            try {
                                                console.log('Suggestion data before logging:', {
                                                    code: suggestion.code,
                                                    code_id: suggestion.code_id,
                                                    owner_id: suggestion.owner_id
                                                });
                                                
                                                await logActivity(
                                                    'visit_code', 
                                                    suggestion.code,
                                                    suggestion.code_id,
                                                    suggestion.owner_id
                                                );
                                                
                                                updateHistory(suggestion);
                                                setCode('');
                                                setSuggestions([]);
                                                window.dispatchEvent(new Event('historyUpdated'));
                                            } catch (error) {
                                                console.error('Error logging activity:', error);
                                            }
                                        }}
                                        sx={{
                                            p: 1,
                                            height: '60px',
                                            borderBottom: `1px solid ${theme.palette.separator.background}`,
                                            '&:last-child': {
                                                borderBottom: 'none'
                                            },
                                            textDecoration: 'none',
                                            color: 'inherit'
                                        }}
                                    >
                                        <Box sx={{ 
                                            display: 'flex', 
                                            alignItems: 'center',
                                            gap: 1,
                                            width: '100%',
                                            minWidth: 0
                                        }}>
                                            {/* Image/Icon Section */}
                                            <Box
                                                sx={{
                                                    width: 40,
                                                    height: 40,
                                                    flexShrink: 0,
                                                    borderRadius: 1,
                                                    overflow: 'hidden',
                                                    backgroundColor: theme.palette.mode === 'dark' 
                                                        ? theme.palette.background.paper
                                                        : theme.palette.grey[200],
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    justifyContent: 'center'
                                                }}
                                            >
                                                {suggestion.imageUrl ? (
                                                    <img
                                                        src={suggestion.imageUrl}
                                                        alt={suggestion.code}
                                                        style={{
                                                            width: '100%',
                                                            height: '100%',
                                                            objectFit: 'cover'
                                                        }}
                                                    />
                                                ) : (
                                                    <Box 
                                                        sx={{
                                                            width: '100%',
                                                            height: '100%',
                                                            display: 'flex',
                                                            alignItems: 'center',
                                                            justifyContent: 'center',
                                                            backgroundColor: theme.palette.primary.main,
                                                            color: theme.palette.primary.contrastText,
                                                            fontSize: '1.2rem',
                                                            fontWeight: 'bold'
                                                        }}
                                                    >
                                                        {suggestion.code.substring(0, 2)}
                                                    </Box>
                                                )}
                                            </Box>

                                            {/* Content Section - adjusted spacing */}
                                            <Box sx={{ 
                                                flex: 1,
                                                minWidth: 0,
                                                display: 'flex',
                                                flexDirection: 'column',
                                                justifyContent: 'flex-start',
                                                height: '60px',
                                                pt: 1
                                            }}>
                                                <Typography 
                                                    variant="body1"
                                                    noWrap
                                                    sx={{ 
                                                        color: theme.palette.text.primary,
                                                        fontSize: '0.8rem',
                                                        lineHeight: 1.1,
                                                        fontWeight: 500,
                                                        mb: 0.1
                                                    }}
                                                >
                                                    {suggestion.code}
                                                </Typography>
                                                <Typography 
                                                    variant="body2"
                                                    noWrap
                                                    sx={{ 
                                                        color: theme.palette.text.primary,
                                                        fontWeight: 'bold',
                                                        fontSize: '0.8rem',
                                                        lineHeight: 1.1,
                                                        mb: 0.1
                                                    }}
                                                >
                                                    {suggestion.codeName}
                                                </Typography>
                                                <Typography 
                                                    variant="body2"
                                                    noWrap
                                                    sx={{ 
                                                        color: theme.palette.text.secondary,
                                                        fontSize: '0.8rem',
                                                        lineHeight: 1.1,
                                                        pb: 0.1
                                                    }}
                                                >
                                                    {suggestion.codeDescription}
                                                </Typography>
                                            </Box>
                                        </Box>
                                    </ListItem>
                                ))}
                            </List>
                        </Paper>
                    )}
                </Box>

                {/* Code Details or Error */}
                {(isDetailVisible || error) && (
                    <Box ref={detailRef} sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        width: '100%',
                      //  minHeight: '-80px',
                        mb: { xs: -2, sm: -3 },
                        boxSizing: 'border-box',
                    }}>
                        {isLoading ? (
                            <CircularProgress />
                        ) : error ? (
                            <Typography color="error" sx={{ fontSize: {
                                 xs: '0.7rem', sm: '1rem' },}}>{error}</Typography>
                        ) : selectedCode && (
                            <Paper 
                                elevation={0}
                                sx={{
                                    backgroundColor: 'transparent',
                                    border: 'none',
                                    p: 0,
                                    width: '100%',
                                    maxWidth: '500px',
                                    boxSizing: 'border-box'
                                }}
                            >
                                <Box 
                                    component="a"
                                    href={selectedCode.url}
                                    target="_blank"
                                    onClick={async (e) => {
                                        try {
                                            await logActivity('visit_code', selectedCode.code, selectedCode.code_id, selectedCode.owner_id);
                                        } catch (error) {
                                            console.error('Error logging activity:', error);
                                        }
                                    }}
                                    sx={{ 
                                        display: 'flex', 
                                        alignItems: 'center',
                                        gap: 2,
                                        width: '100%',
                                        p: 2,
                                        backgroundColor: theme.palette.background.tertiary,
                                        borderRadius: '12px',
                                        cursor: 'pointer',
                                        textDecoration: 'none',
                                        color: theme.palette.text.primary,
                                        transition: 'background-color 0.2s ease',
                                        '&:hover': {
                                            backgroundColor: theme.palette.background.paper
                                        },
                                        overflow: 'hidden'
                                    }}
                                >
                                    {/* Image/Icon Section */}
                                    <Box
                                        sx={{
                                            width: 60,
                                            height: 60,
                                            flexShrink: 0,
                                            borderRadius: 1,
                                            overflow: 'hidden',
                                            backgroundColor: theme.palette.mode === 'dark' 
                                                ? theme.palette.background.paper
                                                : theme.palette.grey[200],
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center'
                                        }}
                                    >
                                        {selectedCode.imageUrl ? (
                                            <img
                                                src={selectedCode.imageUrl}
                                                alt={selectedCode.code}
                                                style={{
                                                    width: '100%',
                                                    height: '100%',
                                                    objectFit: 'cover'
                                                }}
                                            />
                                        ) : (
                                            <Box 
                                                sx={{
                                                    width: '100%',
                                                    height: '100%',
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    justifyContent: 'center',
                                                    backgroundColor: theme.palette.primary.main,
                                                    color: theme.palette.primary.contrastText,
                                                    fontSize: '1.25rem',
                                                    fontWeight: 'bold'
                                                }}
                                            >
                                                {selectedCode.code.substring(0, 2)}
                                            </Box>
                                        )}
                                    </Box>

                                    {/* Content Section */}
                                    <Box sx={{ 
                                        flex: 1,
                                        minWidth: 0,
                                        display: 'flex',
                                        flexDirection: 'column',
                                        justifyContent: 'center',  // Center content vertically
                                        height: '60px'
                                    }}>
                                        <Box sx={{ 
                                            flex: 1,
                                            minWidth: 0,
                                            overflow: 'hidden'
                                        }}>
                                            <Typography 
                                                variant="body1"
                                                sx={{ 
                                                    color: theme.palette.text.primary,
                                                    fontSize: '1.1rem',
                                                    lineHeight: 1.2,
                                                    fontWeight: 500,
                                                    mb: 0.1,
                                                    overflow: 'hidden',
                                                    textOverflow: 'ellipsis',
                                                    whiteSpace: 'nowrap'
                                                }}
                                            >
                                                {selectedCode.code}
                                            </Typography>
                                            <Typography 
                                                variant="body2"
                                                sx={{ 
                                                    color: theme.palette.text.primary,
                                                    fontWeight: 'bold',
                                                    fontSize: '1rem',
                                                    lineHeight: 1.2,
                                                    mb: 0.1,
                                                    overflow: 'hidden',
                                                    textOverflow: 'ellipsis',
                                                    whiteSpace: 'nowrap'
                                                }}
                                            >
                                                {selectedCode.codeName}
                                            </Typography>
                                            <Typography 
                                                variant="body2"
                                                sx={{ 
                                                    color: theme.palette.text.secondary,
                                                    fontSize: '0.9rem',
                                                    lineHeight: 1.2,
                                                    overflow: 'hidden',
                                                    textOverflow: 'ellipsis',
                                                    whiteSpace: 'nowrap'
                                                }}
                                            >
                                                {selectedCode.codeDescription}
                                            </Typography>
                                        </Box>
                                    </Box>

                                    {/* Right chevrons - keep vertical centering */}
                                    <Box sx={{ 
                                        display: 'flex',
                                        alignItems: 'center',
                                        color: theme.palette.text.secondary,
                                        fontSize: '1.1rem',
                                        gap: 0.5,
                                        mr: 2,
                                        height: '100%'  // Take full height to maintain vertical centering
                                    }}>
                                        ›› 
                                    </Box>
                                </Box>
                            </Paper>
                        )}
                    </Box>
                )}
            </Box>

            {/* Scrollable history section */}
            <Box sx={{ 
                overflow: 'auto',
                mt: { xs: 2, sm: 3 },  // Changed from mt: 3
                width: '100%',
                maxWidth: '500px',
                alignItems: 'left'
            }}>
                <Box
                    ref={historyHeaderRef}
                    onClick={() => setIsHistoryExpanded(!isHistoryExpanded)}
                    data-history-toggle="true"
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        cursor: 'pointer',
                        mb: 2,
                        whiteSpace: 'nowrap',
                    }}
                >
                    <Typography 
                        variant="h5"
                        noWrap
                        sx={{ 
                            color: theme.palette.text.primary,
                            fontSize: { xs: '1.0rem', sm: '1.5rem' }
                        }}
                    >
                        My Codes History
                    </Typography>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <Typography 
                            variant="body2"
                            sx={{ 
                                color: theme.palette.text.secondary,
                            }}
                        >
                            ({isHistoryExpanded ? 'close' : 'open'})
                        </Typography>
                        {isHistoryExpanded ? 
                            <KeyboardArrowUpIcon sx={{ color: theme.palette.text.secondary }} /> : 
                            <KeyboardArrowDownIcon sx={{ color: theme.palette.text.secondary }} />
                        }
                    </Box>
                </Box>

                {isHistoryExpanded && (
                    <Box sx={{ width: '100%' }}>
                        <CodeHistory onHistoryItemClick={handleHistoryItemClick} />
                    </Box>
                )}
            </Box>
        </Box>
    );
};

export default UserConsole;