import React, { useState, useEffect, useCallback, useRef } from 'react';
import { 
    Box, 
    Typography, 
    Button,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    IconButton,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    TextField,
    Grid,
    Alert,
    Tooltip,
    useTheme,
    Card,
    CardContent,
    CardMedia,
    Link,
    List,
    ListItem,
    ListItemText,
    Divider,
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import { getOwnerCodes, createCode, updateCode, deleteCode } from '../../services/codeManagementApi';
import { format } from 'date-fns';
import PropTypes from 'prop-types';
import CodeForm from './CodeForm';
import { CodeHistoryItem } from '../CodeHistoryItem';
import CodeManagerDashboard from './CodeManagerDashboard';

const CODE_FORMAT_REGEX = /^[A-Za-z]{2,3}[0-9]{4}$/;

const validateCodeFormat = (code) => {
    return CODE_FORMAT_REGEX.test(code);
};

const formatDateTime = (date) => {
    return date ? format(new Date(date), 'dd-MMM-yyyy') : '-';
};

const StatusChip = ({ status }) => {
    const theme = useTheme();
    const colors = {
        ACTIVE: theme.palette.success.main,
        ARCHIVED: theme.palette.text.disabled,
        RESERVED: theme.palette.info.main
    };

    const labels = {
        ACTIVE: 'Active',
        ARCHIVED: 'Expired',
        RESERVED: 'Reserved'
    };

    return (
        <Box
            sx={{
                backgroundColor: colors[status],
                color: '#fff',
                px: {xs: 0.5, sm: 1},
                py: {xs: 0.2, sm: 0.5},
                borderRadius: 1,
                display: 'inline-block',
                fontSize: {xs: '0.7rem', sm: '0.875rem'},
                fontWeight: 'medium'
            }}
        >
            {labels[status]}
        </Box>
    );
};

const CodeCard = ({ code }) => {
    return (
        <Card sx={{ 
            display: 'flex', 
            width: '100%',
            minWidth: 0,
            backgroundColor: 'background.paper',
            boxShadow: 'none',
            overflow: 'hidden'
        }}>
            <CardMedia
                component="img"
                sx={{ 
                    width: 60, 
                    height: 60,
                    objectFit: 'contain',
                    p: 1,
                    flexShrink: 0
                }}
                image={code.image_url || '/illustrations/buildboard01.png'}
                alt={code.code_name}
            />
            <CardContent sx={{ 
                flex: 1,
                minWidth: 0,
                py: 1,
                px: 2,
                '&:last-child': { pb: 1 },
                overflow: 'hidden'
            }}>
                <Typography 
                    variant="subtitle1" 
                    component="div" 
                    sx={{ 
                        fontWeight: 'bold',
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis'
                    }}
                >
                    {code.code_value}
                </Typography>
                <Typography 
                    variant="body2" 
                    color="text.secondary" 
                    sx={{ 
                        mb: 0.5,
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis'
                    }}
                >
                    {code.code_name}
                </Typography>
                <Typography 
                    variant="body2" 
                    color="text.secondary"
                    sx={{
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis'
                    }}
                >
                    {code.code_description}
                </Typography>
            </CardContent>
        </Card>
    );
};

const ResizableColumn = ({ children, width, onResize }) => {
    const [isResizing, setIsResizing] = useState(false);
    const [initialX, setInitialX] = useState(0);
    const [initialWidth, setInitialWidth] = useState(width);
    const theme = useTheme();

    const startResize = useCallback((e) => {
        e.preventDefault();
        setIsResizing(true);
        setInitialX(e.clientX);
        setInitialWidth(width);
    }, [width]);

    const stopResize = useCallback(() => {
        setIsResizing(false);
    }, []);

    const resize = useCallback((e) => {
        if (isResizing) {
            const diff = e.clientX - initialX;
            const newWidth = Math.max(100, initialWidth + diff);
            onResize(newWidth);
        }
    }, [isResizing, initialX, initialWidth, onResize]);

    useEffect(() => {
        if (isResizing) {
            document.body.style.cursor = 'col-resize';
            window.addEventListener('mousemove', resize);
            window.addEventListener('mouseup', stopResize);
        } else {
            document.body.style.cursor = 'default';
        }

        return () => {
            document.body.style.cursor = 'default';
            window.removeEventListener('mousemove', resize);
            window.removeEventListener('mouseup', stopResize);
        };
    }, [isResizing, resize, stopResize]);

    return (
        <TableCell 
            sx={{ 
                position: 'relative',
                width: width,
                minWidth: width,
                userSelect: isResizing ? 'none' : 'auto',
                borderRight: `1px solid ${theme.palette.table.border}`,
                p: 2,
                '&:hover .resizer': {
                    backgroundColor: theme.palette.table.resizer.hover,
                    opacity: 0.5,
                }
            }}
        >
            {children}
            <Box
                className="resizer"
                sx={{
                    position: 'absolute',
                    right: -3,
                    top: 0,
                    bottom: 0,
                    width: '6px',
                    cursor: 'col-resize',
                    backgroundColor: isResizing 
                        ? theme.palette.table.resizer.hover 
                        : theme.palette.table.resizer.default,
                    opacity: isResizing ? 0.8 : 0.2,
                    transition: 'opacity 0.2s, background-color 0.2s',
                    zIndex: 1000,
                    '&::after': {
                        content: '""',
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: '2px',
                        height: '20px',
                        backgroundColor: isResizing 
                            ? theme.palette.table.resizer.active 
                            : theme.palette.table.resizer.default,
                    }
                }}
                onMouseDown={startResize}
            />
        </TableCell>
    );
};

const addImageTimestamp = (codes) => {
    return codes.map(code => ({
        ...code,
        // Add timestamp to force image refresh
        imageUrl: code.image_url ? `${code.image_url}${code.image_url.includes('?') ? '&' : '?'}t=${Date.now()}` : null
    }));
};

const CodeListItem = React.memo(({ code, refreshImageCodes }) => {
    return (
        <CodeHistoryItem 
            code={{
                code: code.code_value,
                codeName: code.code_name,
                codeDescription: code.code_description,
                imageUrl: code.image_url && refreshImageCodes.has(code.code_value) 
                    ? `${code.image_url}${code.image_url.includes('?') ? '&' : '?'}t=${Date.now()}`
                    : code.image_url,
                url: code.code_url
            }}
            onHistoryItemClick={() => {}}
        />
    );
});

const CodeManager = ({ ownerData }) => {
    const containerRef = useRef(null);
    const [codes, setCodes] = useState([]);
    const [openDialog, setOpenDialog] = useState(false);
    const [editingCode, setEditingCode] = useState(null);
    const [overlappingCodes, setOverlappingCodes] = useState([]);
    
    // Initialize column widths with null values
    const [columnWidths, setColumnWidths] = useState({
        details: null,    // 50%
        status: null,     // 35%
        actions: null     // 15%
    });

    // Add state to track which codes need fresh images
    const [refreshImageCodes, setRefreshImageCodes] = useState(new Set());
    
    // Add state to track initial load
    const [isInitialLoad, setIsInitialLoad] = useState(true);

    // Add useEffect to calculate initial widths
    useEffect(() => {
        const calculateInitialWidths = () => {
            if (containerRef.current) {
                // Account for List padding and gaps between sections
                const totalPadding = 48;  // Container padding (24px * 2)
                const listItemPadding = 48;  // ListItem padding (24px * 2)
                const gapsBetweenSections = 48;  // Total gaps (24px * 2)
                const availableWidth = containerRef.current.offsetWidth - totalPadding - listItemPadding - gapsBetweenSections;

                setColumnWidths({
                    details: Math.floor(availableWidth * 0.5),
                    status: Math.floor(availableWidth * 0.35),
                    actions: Math.floor(availableWidth * 0.15)
                });
            }
        };

        calculateInitialWidths();
        window.addEventListener('resize', calculateInitialWidths);
        return () => window.removeEventListener('resize', calculateInitialWidths);
    }, []);

    const fetchCodes = useCallback(async () => {
        try {
            const response = await getOwnerCodes();
            setCodes(response);
        } catch (error) {
            console.error('Error fetching codes:', error);
        }
    }, [ownerData]);

    useEffect(() => {
        fetchCodes();
    }, [fetchCodes]);

    useEffect(() => {
        console.log('CodeManager mounted with owner data:', ownerData);
    }, [ownerData]);

    useEffect(() => {
        // On initial load, all codes should get fresh images
        if (isInitialLoad && codes.length > 0) {
            setRefreshImageCodes(new Set(codes.map(code => code.code_value)));
            setIsInitialLoad(false);
        }
    }, [codes, isInitialLoad]);

    const handleDialogOpen = (code = null) => {
        if (code) {
            setEditingCode({
                code_id: code.code_id,
                code_value: code.code_value,
                code_name: code.code_name,
                code_description: code.code_description,
                code_url: code.code_url,
                valid_from: code.valid_from,
                valid_until: code.valid_until,
                image_url: code.image_url,
                status: code.status
            });
        } else {
            setEditingCode(null);
        }
        setOpenDialog(true);
    };

    const handleDialogClose = useCallback(() => {
        setOpenDialog(false);
        setEditingCode(null);
    }, []);

    const handleDelete = async (codeValue) => {
        try {
            await deleteCode(codeValue);
            fetchCodes(); // Refresh the list after deletion
        } catch (error) {
            console.error('Failed to delete code:', error);
        }
    };

    const handleColumnResize = (column, newWidth) => {
        setColumnWidths(prev => ({
            ...prev,
            [column]: newWidth
        }));
    };

    // Handle successful code save (create/edit)
    const handleCodeSaved = async () => {
        setEditingCode(null);
        setOpenDialog(false);
        
        try {
            const freshCodes = await getOwnerCodes();
            // Use the renamed function
            setCodes(addImageTimestamp(freshCodes));
        } catch (error) {
            console.error('Error refreshing codes:', error);
        }
    };

    const theme = useTheme();

    return (
        <Box ref={containerRef} sx={{ 
            flex: 1,
            px: 0
        }}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2, width: '100%' }}>
                <Box sx={{ flex: 1 }}>
                    <Typography 
                        variant="h6" 
                        sx={{ 
                            color: 'white', 
                            textAlign: 'left',
                            fontSize: { xs: '0.8rem', sm: '1.25rem' }  // Added responsive font size
                        }}
                    >
                        Welcome, {ownerData?.email}!
                    </Typography>
                </Box>
                <Box>
                    <Button
                        variant="contained"
                        onClick={() => handleDialogOpen()}
                        startIcon={<AddIcon />}
                        sx={{
                            fontSize: { xs: '0.8rem', sm: '1rem' },  // Added responsive font size
                            backgroundColor: '#90caf9',
                            '&:hover': { backgroundColor: '#64b5f6' }
                        }}
                    >
                        ADD NEW CODE
                    </Button>
                </Box>
            </Box>

            <List sx={{ 
                height: { xs: 'calc(100vh - 600px)', sm: 'calc(100vh - 560px)' },
                overflow: 'auto',
                width: '100%',
                p: 0,
                '&::-webkit-scrollbar': {
                    width: '8px',
                },
                '&::-webkit-scrollbar-track': {
                    background: theme.palette.scrollbar.track,
                    borderRadius: '4px',
                },
                '&::-webkit-scrollbar-thumb': {
                    background: theme.palette.scrollbar.thumb,
                    borderRadius: '4px',
                    '&:hover': {
                        background: theme.palette.scrollbar.thumbHover,
                    },
                },
            }}>
                {codes.sort((a, b) => a.code_value.localeCompare(b.code_value))
                    .map((code, index) => (
                        <React.Fragment key={code.code_id}>
                            <ListItem
                                sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    gap: { xs: 0.2, sm: 1 },
                                    py: { xs: 0.2, sm: 1 },
                                    px: { xs: 0.2, sm: 1 },
                                    backgroundColor: theme.palette.background.tertiary,
                                    borderRadius: '12px',
                                    mb: index < codes.length - 1 ? 1.5 : 0,
                                    '&:hover': {
                                        backgroundColor: theme.palette.background.paper
                                    },
                                    transition: 'background-color 0.2s ease',
                                    maxWidth: '100%',
                                    boxSizing: 'border-box',
                                    mx: 0
                                }}
                            >
                                {/* Code Details Card - Left side */}
                                <Box sx={{ 
                                    width: '50%',
                                    minWidth: 0,
                                    display: 'flex',
                                    alignItems: 'center'
                                }}>
                                    <CodeListItem 
                                        code={code}
                                        refreshImageCodes={refreshImageCodes}
                                    />
                                </Box>

                                {/* Status & Info - Middle */}
                                <Box sx={{ 
                                    width: '35%',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    gap: { xs: 0.0, sm: 0.3 },
                                    minWidth: 0,
                                    alignSelf: 'center'
                                }}>
                                    <StatusChip status={code.status} />
                                    <Typography 
                                        variant="body2" 
                                        color="text.secondary"
                                        sx={{
                                            whiteSpace: 'nowrap',
                                            overflow: 'hidden',
                                            textOverflow: 'ellipsis',
                                            fontSize: {xs: '0.7rem', sm: '0.875rem'}
                                        }}
                                    >
                                        URL: {code.code_url}
                                    </Typography>
                                    <Typography 
                                        variant="body2" 
                                        color="text.secondary" 
                                        sx={{
                                            whiteSpace: 'nowrap',
                                            overflow: 'hidden',
                                            textOverflow: 'ellipsis',
                                            fontSize: {xs: '0.7rem', sm: '0.875rem'}
                                        }}
                                    >
                                        From: {formatDateTime(code.valid_from)}
                                    </Typography>
                                    <Typography 
                                        variant="body2" 
                                        color="text.secondary" 
                                        sx={{
                                            whiteSpace: 'nowrap',
                                            overflow: 'hidden',
                                            textOverflow: 'ellipsis',
                                            fontSize: {xs: '0.7rem', sm: '0.875rem'}
                                        }}
                                    >
                                        Until: {formatDateTime(code.valid_until)}
                                    </Typography>
                                </Box>

                                {/* Actions - Right side */}
                                <Box sx={{ 
                                    width: '15%',
                                    display: 'flex',
                                    flexDirection: {xs: 'column', sm: 'row'},
                                    justifyContent: 'flex-end',
                                    alignItems: 'center',
                                    gap: {xs: 0.5, sm: 1},
                                    minWidth: 'auto',
                                    alignSelf: 'center'
                                }}>
                                    <IconButton 
                                        onClick={() => handleDialogOpen(code)}
                                        disabled={code.status === 'ARCHIVED'}
                                        size="small"
                                        sx={{ 
                                            color: 'primary.main',
                                            '&:hover': { backgroundColor: 'primary.light' }
                                        }}
                                    >
                                        <EditIcon />
                                    </IconButton>
                                    <IconButton 
                                        onClick={() => handleDelete(code.code_value)}
                                        disabled={code.status === 'ARCHIVED'}
                                        size="small"
                                        sx={{ 
                                            color: 'error.main',
                                            '&:hover': { backgroundColor: 'error.light' }
                                        }}
                                    >
                                        <DeleteIcon />
                                    </IconButton>
                                </Box>
                            </ListItem>
                        </React.Fragment>
                    ))}
            </List>

            {/* New dashboard box */}
            <Box sx={{
                width: '100%',
                height: '300px',
                backgroundColor: theme.palette.background.tertiary,
                borderRadius: 2,
                mt: {xs: 1, sm: 2},
               // p: 2,
                display: 'flex',
                flexDirection: 'column'  // Stack children vertically
            }}>
                    <CodeManagerDashboard codes={codes} />
                
            </Box>

            {openDialog && (
                <CodeForm 
                    open={openDialog}
                    onClose={handleDialogClose}
                    editingCode={editingCode}
                    onCodeSaved={handleCodeSaved}
                />
            )}
        </Box>
    );
};

CodeManager.propTypes = {
    ownerData: PropTypes.shape({
        email: PropTypes.string,
        token: PropTypes.string.isRequired,
    })
};

export default CodeManager; 