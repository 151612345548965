import axios from 'axios';
import config from '../config';
import { getUserId } from './userService';

const api = axios.create({
    baseURL: config.apiBaseUrl,
    headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json'
    },
    withCredentials: false
});

const codeOwnerApi = axios.create({
    baseURL: config.codeOwnerApiUrl,
    headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json'
    },
    withCredentials: false
});

// Update request interceptor for both APIs
[api, codeOwnerApi].forEach(instance => {
    instance.interceptors.request.use(request => {
        console.log('Starting Request:', {
            url: request.url,
            method: request.method,
            headers: request.headers,
            data: request.data
        });
        
        // Only use stored token for codeOwnerApi
        if (instance === codeOwnerApi) {
            const token = localStorage.getItem('authToken');
            if (token) {
                request.headers['Authorization'] = `Bearer ${token}`;
            }
        }
        
        return request;
    });

    instance.interceptors.response.use(
        response => {
            console.log('Response:', {
                status: response.status,
                headers: response.headers,
                data: response.data
            });
            return response;
        },
        error => {
            console.error('API Error:', {
                message: error.message,
                response: error.response?.data,
                status: error.response?.status
            });
            return Promise.reject(error);
        }
    );
});

// Let's add some debugging to track API calls
let isLoggingActivity = false;  // Add this flag

export const searchCodes = async (query) => {
    try {
        console.log('Searching codes at:', `${config.apiBaseUrl}/api/codes/search?q=${encodeURIComponent(query)}`);
        const response = await api.get(`/api/codes/search?q=${encodeURIComponent(query)}`);
        return response.data.map(item => ({
            code: item.code,
            code_id: item.code_id,
            owner_id: item.owner_id,
            codeName: item.code_name,
            codeDescription: item.codedescription,
            url: item.url,
            imageUrl: item.imageurl ? `${item.imageurl}${item.imageurl.includes('?') ? '&' : '?'}t=${Date.now()}` : null
        }));
    } catch (error) {
        console.error('Search error details:', error);
        return [];
    }
};

export const getCode = async (code) => {
    try {
        if (!code) {
            throw new Error('Code parameter is required');
        }
        console.log('Fetching code:', code);
        const userId = await getUserId();
        const response = await api.get(`/api/codes/${code}`, {
            headers: {
                'x-user-uuid': userId
            }
        });
        
        const item = Array.isArray(response.data) ? response.data[0] : response.data;
        
        const codeData = {
            code_id: item.code_id,
            code: item.code,
            codeName: item.code_name,
            codeDescription: item.codedescription,
            url: item.url,
            imageUrl: item.imageurl,
            owner_id: item.owner_id
        };
        
        console.log('Processed code data:', codeData);
        return codeData;
    } catch (error) {
        console.error('Error fetching code:', error);
        throw error;
    }
};

export const logActivity = async (activityType, codeValue, codeId, ownerId = null) => {
    if (isLoggingActivity) {
        console.log('Activity logging already in progress, skipping...');
        return Promise.resolve();
    }
    
    try {
        isLoggingActivity = true;
        const userId = await getUserId();
        
        if (!userId) {
            throw new Error('Failed to get user ID');
        }

        console.log('Logging activity with:', { activityType, codeValue, codeId, ownerId }); // Debug log

        const response = await api.post('/api/activities', {
            activityType,
            codeValue,
            codeId: parseInt(codeId),
            ownerId: ownerId ? parseInt(ownerId) : null
        }, {
            headers: {
                'x-user-uuid': userId
            }
        });

        console.log('Activity logged successfully:', response.data);
        return response.data;
    } catch (error) {
        console.error('Error logging activity:', error);
        return Promise.reject(error);
    } finally {
        setTimeout(() => {
            isLoggingActivity = false;
        }, 300);
    }
};

export const uploadCodeImage = async (code, imageFile) => {
    try {
        const formData = new FormData();
        formData.append('image', imageFile);

        const response = await api.post(
            `/codes/${code}/image`,
            formData,
            {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            }
        );
        return response.data.imageUrl;
    } catch (error) {
        console.error('Error uploading image:', error);
        throw error;
    }
};

export const createUser = async (userData) => {
    try {
        // Ensure platform is set for web users
        const webUserData = {
            ...userData,
            platform: 'web',
            encrypted_apple_id: null
        };
        const response = await api.post('/api/users', webUserData);
        return response.data;
    } catch (error) {
        console.error('Error creating user:', error);
        throw error;
    }
};

export const verifyUser = async (userId) => {
    try {
        const response = await api.get(`/api/users/${userId}`);
        return response.data;
    } catch (error) {
        if (error.response?.status === 404) {
            return null;  // Gracefully handle missing user
        }
        throw error;
    }
};

// Check if email exists and get registration status
export const checkEmailExists = async (email) => {
    console.log('API: Attempting to check email:', email);
    try {
        const response = await codeOwnerApi.post('/api/auth/owner/check-email', { email });
        console.log('API: Email check response:', response.data);
        return response.data;
    } catch (error) {
        // Handle network errors
        if (!error.response || error.message === 'Network Error') {
            throw new Error('Connection error. Please check your Internet or try again later.');
        }
        console.error('API: Email check failed:', {
            error: error.message,
            status: error.response?.status,
            data: error.response?.data
        });
        throw error;
    }
};

// Sign up code owner
export const signUpCodeOwner = async (email, password) => {
    try {
        // Basic email validation before making API call
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailRegex.test(email)) {
            throw new Error("This email doesn't seem to be a real one. Please try again.");
        }
        
        console.log('Attempting signup with:', { 
            email, 
            hasPassword: !!password 
        });
        
        const response = await codeOwnerApi.post('/api/auth/owner/signup', { 
            email, 
            password 
        });
        return response.data;
    } catch (error) {
        // If it's our custom email validation error, throw it as is
        if (error.message === "This email doesn't seem to be a real one. Please try again.") {
            throw error;
        }

        console.error('Signup error details:', {
            status: error.response?.status,
            data: error.response?.data,
            message: error.message
        });

        // If server returns validation error, convert it to user-friendly message
        if (error.response?.status === 400 && error.response?.data?.error === 'Validation failed') {
            throw new Error("This email doesn't seem to be a real one. Please try again.");
        }

        // Handle other errors
        if (error.response?.data?.error?.includes('Firebase')) {
            throw new Error('Authentication service error');
        }
        throw error;
    }
};

// Verify OTP for code owner
export const verifyCodeOwnerOTP = async (email, otp) => {
    try {
        const response = await codeOwnerApi.post('/api/auth/owner/verify-email', { email, otp });
        console.log('OTP verification response:', {
            hasToken: !!response.data.token,
            tokenType: response.data.tokenType,
            tokenLength: response.data.token?.length
        });

        if (response.data.token) {
            // Store token type along with the token
            localStorage.setItem('authTokenType', response.data.tokenType || 'custom');
            localStorage.setItem('authToken', response.data.token);
            
            // If it's a custom token, exchange it for an ID token
            if (response.data.tokenType === 'custom') {
                try {
                    const idTokenResponse = await codeOwnerApi.post('/api/auth/owner/exchange-token', {
                        customToken: response.data.token
                    });
                    if (idTokenResponse.data.idToken) {
                        localStorage.setItem('authToken', idTokenResponse.data.idToken);
                        localStorage.setItem('authTokenType', 'id');
                        console.log('Successfully exchanged custom token for ID token');
                    }
                } catch (exchangeError) {
                    console.error('Failed to exchange token:', exchangeError);
                }
            }
        }
        return response.data;
    } catch (error) {
        console.error('OTP verification error:', error);
        throw error;
    }
};

// Login code owner
export const loginCodeOwner = async (email, password) => {
    try {
        const response = await codeOwnerApi.post('/api/auth/owner/login', { 
            email, 
            password 
        });
        return response.data;
    } catch (error) {
        throw error;
    }
};

// Request password reset
export const requestPasswordReset = async (email) => {
    try {
        const response = await codeOwnerApi.post('/api/auth/owner/forgot-password', { email });
        return response.data;
    } catch (error) {
        console.error('Password reset request error:', error);
        throw error;
    }
};

// Reset password with OTP
export const resetPassword = async (email, otp, newPassword) => {
    try {
        const response = await codeOwnerApi.post('/api/auth/owner/reset-password', {
            email,
            otp,
            newPassword
        });
        return response.data;  // Should include token in the response
    } catch (error) {
        console.error('Password reset error:', error);
        throw error;
    }
};

// Complete registration with Firebase
export const completeRegistration = async (email, firebaseUid) => {
    const token = localStorage.getItem('authToken');
    const response = await codeOwnerApi.post('/api/auth/owner/complete-registration', 
        { email, firebaseUid },
        {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        }
    );
    return response.data;
};

// Verify OTP for password reset
export const verifyResetOtp = async (email, otp) => {
    console.log('Verifying reset OTP:', { email, otp });
    try {
        const response = await codeOwnerApi.post('/api/auth/owner/verify-reset-otp', {
            email,
            otp
        });
        return response.data;
    } catch (error) {
        console.error('OTP verification error:', error);
        throw error;
    }
};

// Add fallback images
const FALLBACK_IMAGES = [
    '/illustrations/buildboard01.png',
    '/illustrations/bus01.png',
    '/illustrations/construction01.png',
    '/illustrations/person01.jpg',
    '/illustrations/radioDJ01.jpg',
    '/illustrations/taxi01.png'
];

export const getSlideshowImages = async () => {
    try {
        const response = await api.get('/api/slideshow-images');
        const s3Images = response.data.images.map(image => 
            `https://s3.amazonaws.com/get-code-content/slideshow/${image}`
        );
        return s3Images.length > 0 ? s3Images : FALLBACK_IMAGES;
    } catch (error) {
        console.error('Error fetching slideshow images:', error);
        return FALLBACK_IMAGES;
    }
}; 