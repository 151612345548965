import React from 'react';
import { Box } from '@mui/material';
import logoImage from '../assets/images/GetCodeIcon.png';

function AppLogo({ size = 40 }) {
    return (
        <Box
            component="img"
            src={logoImage}
            alt="Get-Code Logo"
            sx={{
                width: size,
                height: size,
                display: 'block',
                borderRadius: '20%',
                objectFit: 'contain'
            }}
        />
    );
}

export default AppLogo; 