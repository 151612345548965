import React, { useState, useEffect } from 'react';
import { Box, Typography, Container, Button, useTheme, useMediaQuery, CircularProgress, Divider } from '@mui/material';
import ImageSlideshow from './ImageSlideshow';
import { getSlideshowImages } from '../services/api';

const HeroSlideshow = ({ onUserCTAClick, onBusinessCTAClick }) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const [currentIndex, setCurrentIndex] = useState(0);
    const [manualMode, setManualMode] = useState(false);
    const [manualTimeout, setManualTimeout] = useState(null);
    const [images, setImages] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [imagesLoaded, setImagesLoaded] = useState(0);
    const [totalImages, setTotalImages] = useState(0);
    const BASE_INTERVAL = 7000;

    // Preload images
    const preloadImages = (imageUrls) => {
        setTotalImages(imageUrls.length);
        return Promise.all(
            imageUrls.map(url => {
                return new Promise((resolve, reject) => {
                    const img = new Image();
                    img.onload = () => {
                        setImagesLoaded(prev => prev + 1);
                        resolve(url);
                    };
                    img.onerror = () => reject(url);
                    img.src = url;
                });
            })
        );
    };

    // Fetch and preload images
    useEffect(() => {
        const loadImages = async () => {
            setIsLoading(true);
            try {
                const fetchedImages = await getSlideshowImages();
                await preloadImages(fetchedImages);
                setImages(fetchedImages);
            } catch (error) {
                console.error('Failed to load slideshow images:', error);
            } finally {
                setIsLoading(false);
            }
        };

        loadImages();
        const refreshInterval = setInterval(loadImages, 5 * 60 * 1000);
        return () => clearInterval(refreshInterval);
    }, []);

    // Auto-advance slideshow
    useEffect(() => {
        if (images.length === 0 || manualMode) return;

        const timer = setInterval(() => {
            setCurrentIndex(current => (current + 1) % images.length);
        }, BASE_INTERVAL);

        return () => clearInterval(timer);
    }, [images.length, manualMode]);

    const handleManualNavigate = (direction) => {
        setManualMode(true);
        if (manualTimeout) clearTimeout(manualTimeout);

        setCurrentIndex(current => {
            if (direction === 'prev') {
                return current === 0 ? images.length - 1 : current - 1;
            } else {
                return (current + 1) % images.length;
            }
        });

        const timeout = setTimeout(() => setManualMode(false), 10000);
        setManualTimeout(timeout);
    };

    // Show loading state with progress
    if (isLoading) {
        return (
            <Container 
                maxWidth="lg" 
                disableGutters 
                sx={{ 
                    position: 'relative',
                    my: { xs: 0, sm: 4 }   // Match loaded state margins
                }}
            >
                <Box sx={{
                    position: 'relative',
                    width: '100%',
                    height: { xs: '150px', sm: '400px' },
                    borderRadius: { xs: 0, sm: 1 },
                    overflow: 'hidden',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center'
                }}>
                    <CircularProgress />
                    {totalImages > 0 && (
                        <Typography sx={{ mt: 2 }}>
                            Loading images... {Math.round((imagesLoaded / totalImages) * 100)}%
                        </Typography>
                    )}
                </Box>
            </Container>
        );
    }

    return (
        <Container 
            maxWidth="lg" 
            disableGutters 
            sx={{ 
                position: 'relative',
                my: { 
                    xs: 0,  // No vertical margin on mobile
                    sm: 4   // Normal margin on larger screens
                }
            }}
        >
            <Box sx={{
                position: 'relative',
                width: '100%',
                height: { 
                    xs: '150px',  // Adjust height for mobile if needed
                    sm: '400px'   // Normal height for larger screens
                },
                borderRadius: { 
                    xs: 0,        // No border radius on mobile
                    sm: 1         // Border radius on larger screens
                },
                overflow: 'hidden'
            }}>
                <ImageSlideshow 
                    images={images}
                    currentIndex={currentIndex}
                    onManualNavigate={handleManualNavigate}
                />
                {/* Slide indicators */}
                <Box sx={{
                    position: 'absolute',
                    bottom: { xs: 8, sm: 16 },
                    left: '50%',
                    transform: 'translateX(-50%)',
                    display: 'flex',
                    gap: { xs: 0.5, sm: 1 },
                    zIndex: 1
                }}>
                    {images.map((_, index) => (
                        <Box
                            key={index}
                            sx={{
                                width: { xs: 6, sm: 8 },
                                height: { xs: 6, sm: 8 },
                                borderRadius: '50%',
                                backgroundColor: currentIndex === index 
                                    ? theme.palette.primary.main
                                    : theme.palette.action.disabled,
                                cursor: 'pointer',
                                transition: theme.transitions.create(['background-color'], {
                                    duration: theme.transitions.duration.standard
                                }),
                                '&:hover': {
                                    backgroundColor: currentIndex === index 
                                        ? theme.palette.primary.dark
                                        : theme.palette.action.hover
                                },
                                boxShadow: theme.shadows[1]
                            }}
                            onClick={() => {
                                setCurrentIndex(index);
                                setManualMode(true);
                                if (manualTimeout) clearTimeout(manualTimeout);
                                const timeout = setTimeout(() => setManualMode(false), 10000);
                                setManualTimeout(timeout);
                            }}
                        />
                    ))}
                </Box>
            </Box>
        </Container>
    );
};

export default HeroSlideshow; 