import React, { useEffect, useState } from 'react';
import { List, ListItem, useTheme } from '@mui/material';
import { CodeHistoryItem } from './CodeHistoryItem';

const CodeHistory = ({ onHistoryItemClick }) => {
    const [history, setHistory] = useState([]);
    const theme = useTheme();

    useEffect(() => {
        const loadHistory = () => {
            const savedHistory = JSON.parse(localStorage.getItem('codeHistory') || '[]');
            setHistory(savedHistory);
        };

        loadHistory();
        window.addEventListener('storage', loadHistory);
        window.addEventListener('historyUpdated', loadHistory);

        return () => {
            window.removeEventListener('storage', loadHistory);
            window.removeEventListener('historyUpdated', loadHistory);
        };
    }, []);

    return (
        <List
            sx={{
                maxHeight: '400px',
                overflowY: 'auto',
                overflowX: 'hidden',
                '&::-webkit-scrollbar': {
                    width: '8px',
                },
                '&::-webkit-scrollbar-track': {
                    background: theme.palette.scrollbar.track,
                    borderRadius: '4px',
                },
                '&::-webkit-scrollbar-thumb': {
                    background: theme.palette.scrollbar.thumb,
                    borderRadius: '4px',
                    '&:hover': {
                        background: theme.palette.scrollbar.thumbHover,
                    },
                },
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                gap: 1.5,
               // p: 1.5,
                boxSizing: 'border-box',
            }}
        >
            {history.map((item, index) => (
                <ListItem
                    key={`${item.code}-${index}`}
                    sx={{
                        p: 0,
                        borderRadius: 2,
                        overflow: 'visible',
                        border: 'none',
                        width: '100%',
                        display: 'block',
                    }}
                    disablePadding
                >
                    <CodeHistoryItem 
                        code={item} 
                        onHistoryItemClick={onHistoryItemClick}
                    />
                </ListItem>
            ))}
        </List>
    );
};

export default CodeHistory; 