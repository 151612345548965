import React, { useState, useEffect, useCallback, useMemo } from 'react';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    TextField,
    Button,
    Stepper,
    Step,
    StepLabel,
    Box,
    Typography,
    Alert,
    Autocomplete,
    Paper,
    CircularProgress,
    LinearProgress,
    Card,
    CardMedia,
    Chip,
    IconButton
} from '@mui/material';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import { addMonths, isBefore, isAfter, format } from 'date-fns';
import PropTypes from 'prop-types';
import { 
    createCode, 
    updateCode, 
    checkCodeAvailability as checkCodeAvailabilityApi,
    validateUrl,
    getPaidCodes,
    checkContentModeration,
    uploadImage
} from '../../services/codeManagementApi';
import { styled } from '@mui/material/styles';
import InfoIcon from '@mui/icons-material/Info';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';
import PendingIcon from '@mui/icons-material/Pending';
import { normalizeUrl } from '../../services/urlUtils';
import CloseIcon from '@mui/icons-material/Close';
import { debounce } from 'lodash';
import ImageUploadDialog from './ImageUploadDialog';
import { ReactCrop } from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { optimizeImage, createImagePreview, calculateInitialCrop, getCroppedImage } from './ImageHelper';
import moment from 'moment-timezone';

const getSteps = (isEditMode) => [
    'Guide',
    'Enter URL',
    isEditMode ? 'View Period' : 'Select Period',
    isEditMode ? 'View Code' : 'Choose Code',
    'Add Details',
    'Add Image'
];

const MAX_RENTAL_MONTHS = 5;

// Update code format constant
const CODE_REGEX = /^[A-Z]{2,3}[0-9]{4}$/;
const MAX_PREFIX_LENGTH = 3;

// Add input validation helper
const isValidCodeInput = (input, position) => {
    const char = input.toUpperCase();
    if (position <= 2) {
        return /^[A-Z]$/.test(char);
    }
    if (position === 3) {
        return /^[A-Z0-9]$/.test(char);
    }
    return /^[0-9]$/.test(char);
};

// Styled components
const StyledTextField = styled(TextField)(({ theme }) => ({
    '& .MuiOutlinedInput-root': {
        backgroundColor: theme.palette.background.paper,
        '& fieldset': {
            borderColor: 'transparent',
            borderWidth: '1px'
        },
        '&:hover fieldset': {
            borderColor: theme.palette.primary.main,
        },
        '&.Mui-focused fieldset': {
            borderColor: theme.palette.primary.main,
        },
        '&.Mui-error fieldset': {
            borderColor: theme.palette.error.main,
        }
    },
    '& .MuiInputLabel-root': {
        color: theme.palette.text.secondary
    }
}));

const StyledStepper = styled(Stepper)(({ theme }) => ({
    marginBottom: theme.spacing(2),
   // backgroundColor: theme.palette.background.paper,
    '& .MuiStepLabel-root': {
        '& .MuiStepLabel-label': {
            color: theme.palette.text.secondary,
            '&.Mui-active': {
                color: theme.palette.text.primary
            }
        }
    },
    '& .MuiStepIcon-root': {
        color: theme.palette.action.disabled,
        '&.Mui-active': {
            color: theme.palette.primary.main
        },
        '&.Mui-completed': {
            color: theme.palette.success.main
        }
    }
}));

const StyledDateField = styled(TextField)(({ theme }) => ({
    '& .MuiOutlinedInput-root': {
        backgroundColor: theme.palette.background.paper,
        '& fieldset': {
            borderColor: 'transparent',
            borderWidth: '1px'
        },
        '&:hover fieldset': {
            borderColor: theme.palette.primary.main,
        },
        '&.Mui-focused fieldset': {
            borderColor: theme.palette.primary.main,
        }
    },
    '& .MuiInputLabel-root': {
        color: theme.palette.text.secondary
    },
    '& .MuiInputBase-input': {
        fontSize: '1.1rem',
        padding: '12px'
    }
}));

// Update validation line component
const ValidationLine = ({ label, status, content }) => (
    <Box sx={{ 
        display: 'flex', 
        alignItems: 'center',
        minHeight: { xs: '40px', sm: '48px' },
        gap: { xs: 0.5, sm: 2 },
        py: { xs: 0.5, sm: 1 },
        borderBottom: '1px solid',
        borderColor: 'divider'
    }}>
        <Box sx={{ 
            width: { xs: '90px', sm: '150px' },
            minWidth: { xs: '90px', sm: '150px' },
            display: 'flex',
            alignItems: 'center'
        }}>
            <Typography 
                component="span" 
                color="text.secondary"
                sx={{
                    fontSize: {
                        xs: '0.8rem',
                        sm: '1.2rem'
                    }
                }}
            >
                {label}
            </Typography>
        </Box>
        <Box sx={{ 
            display: 'flex', 
            alignItems: 'center',
            gap: { xs: 0.5, sm: 2 },
            flex: 1
        }}>
            <Box sx={{ 
                display: 'flex',
                alignItems: 'center',
                minWidth: { xs: '20px', sm: '24px' }
            }}>
                {status === 'pending' && (
                    <CircularProgress 
                        size={{ xs: 20, sm: 30 }}
                        sx={{ 
                            width: { xs: 20, sm: 30 },
                            height: { xs: 20, sm: 30 }
                        }}
                    />
                )}
                {status === 'success' && <CheckCircleIcon sx={{ fontSize: { xs: 20, sm: 30 } }} color="success" />}
                {status === 'error' && <ErrorIcon sx={{ fontSize: { xs: 20, sm: 30 } }} color="error" />}
                {status === 'inactive' && (
                    <Box sx={{ 
                        width: { xs: 20, sm: 30 }, 
                        height: { xs: 20, sm: 30 }, 
                        borderRadius: '50%',
                        bgcolor: 'action.disabled' 
                    }} />
                )}
            </Box>
            <Typography 
                component="span"
                sx={{
                    fontSize: {
                        xs: '1.0rem',
                        sm: '1.0rem'
                    },
                    flex: 1,
                    display: 'flex',
                    alignItems: 'center',
                    color: status === 'success' ? 'success.main' : 
                           status === 'error' ? 'error.main' : 
                           'text.secondary'
                }}
            >
                {content}
            </Typography>
        </Box>
    </Box>
);

// Add price display component
const CodePriceSection = ({ code, price }) => (
    <Box sx={{ 
        display: 'flex', 
        alignItems: 'center',
        height: '56px', // Match TextField height
        width: 'fit-content', // Minimum width to fit content
    }}>
        <Typography 
            variant="body1" 
            sx={{ 
                color: theme => price > 0 ? 
                    theme.palette.primary.main : 
                    theme.palette.success.main,
                fontWeight: 'medium'
            }}
        >
            ${price}/month
        </Typography>
    </Box>
);

// First, let's extract the validation message logic into a separate function
const getValidationMessage = (code) => {
    if (!code) return 'Enter code...';
    const letterPrefix = code.replace(/[0-9]/g, '');
    const numberPart = code.replace(/[A-Z]/g, '');
    
    // Validate prefix first
    if (letterPrefix.length > 3) {
        return 'Prefix too long. Use 2 or 3 letters only';
    }
    if (letterPrefix.length < 2) {
        return 'Start with 2 or 3 letters (Country or City code)';
    }
    if (letterPrefix.length === 2) {
        // For 2 letters, suggest the optional third letter
        if (numberPart.length === 0) {
            return 'Continue... 1 letter (optional) and 4 numbers';
        }
        if (numberPart.length < 4) {
            const remaining = 4 - numberPart.length;
            return `Continue... need ${remaining} more ${remaining === 1 ? 'number' : 'numbers'}`;
        }
        if (numberPart.length > 4) {
            return 'Too many numbers. Use exactly 4 numbers';
        }
    }
    if (letterPrefix.length === 3) {
        // For 3 letters, only check numbers
        if (numberPart.length < 4) {
            const remaining = 4 - numberPart.length;
            return `Continue... need ${remaining} more ${remaining === 1 ? 'number' : 'numbers'}`;
        }
        if (numberPart.length > 4) {
            return 'Too many numbers. Use exactly 4 numbers';
        }
    }
    
    // If we have correct length but invalid format
    if (!CODE_REGEX.test(code)) {
        return 'Invalid format. Must be 2 or 3 letters followed by 4 numbers';
    }
    
    return 'Valid code format';
};

// Add this helper function at the top level
const formatCodeInput = (value) => {
    const letters = value.replace(/[^A-Z]/g, '').slice(0, 3);
    const numbers = value.replace(/[^0-9]/g, '').slice(0, 4);
    return `${letters}${numbers}`;
};

// Update the CodeSuggestions component
const CodeSuggestions = ({ validationMessage, suggestions, onSelect }) => {
    // Check if code is complete (has 4 numbers)
    const isCodeComplete = validationMessage === 'Valid code format';

    return (
        <Paper sx={{
            mt: 0.5,
            maxHeight: 300,
            bgcolor: 'background.paper',
            borderRadius: 1,
            border: '1px solid',
            borderColor: 'divider',
            display: 'flex',
            flexDirection: 'column'
        }}>
            {/* Validation message (always shown) */}
            <Box sx={{
                p: 1.5,
                borderBottom: !isCodeComplete && (suggestions.paid.length > 0 || suggestions.free.length > 0) ? '1px solid' : 'none',
                borderColor: 'divider',
                bgcolor: 'background.paper'
            }}>
                <Typography variant="body2" color="text.secondary">
                    {validationMessage}
                </Typography>
            </Box>

            {/* Only show suggestions if code is not complete */}
            {!isCodeComplete && (suggestions.paid.length > 0 || suggestions.free.length > 0) && (
                <>
                    <Box sx={{
                        position: 'sticky',
                        top: 0,
                        bgcolor: 'background.paper',
                        zIndex: 1,
                        borderBottom: '1px solid',
                        borderColor: 'divider'
                    }}>
                        <Typography variant="caption" sx={{ px: 1.5, py: 0.5, display: 'block', color: 'text.secondary' }}>
                            Some examples you can use:
                        </Typography>
                    </Box>

                    <Box sx={{ overflow: 'auto' }}>
                        {suggestions.paid.map((suggestion, index) => (
                            <Box
                                key={`paid-${index}`}
                                sx={{
                                    p: 1.0,
                                    cursor: 'pointer',
                                    '&:hover': { bgcolor: 'action.hover' },
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    alignItems: 'center'
                                }}
                                onClick={() => onSelect(suggestion.value)}
                            >
                                <Typography>{suggestion.value}</Typography>
                                <Typography 
                                    variant="caption" 
                                    sx={{ color: theme => theme.palette.primary.main }}
                                >
                                    ${suggestion.price}/month
                                </Typography>
                            </Box>
                        ))}

                        {suggestions.free.map((suggestion, index) => (
                            <Box
                                key={`free-${index}`}
                                sx={{
                                    p: 1.0,
                                    cursor: 'pointer',
                                    '&:hover': { bgcolor: 'action.hover' },
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    alignItems: 'center'
                                }}
                                onClick={() => onSelect(suggestion.value)}
                            >
                                <Typography>{suggestion.value}</Typography>
                                <Typography 
                                    variant="caption" 
                                    sx={{ color: theme => theme.palette.success.main }}
                                >
                                    $0/month
                                </Typography>
                            </Box>
                        ))}
                    </Box>
                </>
            )}
        </Paper>
    );
};

// Update CodeAvailabilityCheck component
const CodeAvailabilityCheck = ({ 
    code, 
    validFrom, 
    validUntil, 
    overlappingPeriods = [], 
    isChecking = false, 
    paidCode = null 
}) => {
    if (isChecking) {
        return (
            <Box sx={{ p: 2, bgcolor: 'background.paper', borderRadius: 1, textAlign: 'center' }}>
                <CircularProgress size={24} sx={{ mb: 2 }} />
                <Typography color="text.secondary">
                    Checking availability...
                </Typography>
            </Box>
        );
    }

    return (
        <Box sx={{ p: 2, bgcolor: 'background.paper', borderRadius: 1 }}>
            {overlappingPeriods.length > 0 ? (
                <>
                    <Typography color="error.main" variant="subtitle1" sx={{ mb: 2 }}>
                        Code is not available for selected period
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                        Would you like to select a different code or different period?
                    </Typography>
                    <Box sx={{ mt: 2 }}>
                        {overlappingPeriods.map((period, index) => (
                            <Box key={index} sx={{ 
                                p: 1, 
                                bgcolor: 'error.light',
                                borderRadius: 1,
                                mb: 1
                            }}>
                                <Typography variant="caption">
                                    {format(new Date(period.valid_from), 'dd MMM yyyy')} - 
                                    {format(new Date(period.valid_until), 'dd MMM yyyy')}
                                </Typography>
                            </Box>
                        ))}
                    </Box>
                </>
            ) : (
                <Box sx={{ textAlign: 'center' }}>
                    <CheckCircleIcon color="success" sx={{ fontSize: 48, mb: 2 }} />
                    <Typography color="success.main" variant="subtitle1">
                        Code is available for selected period
                    </Typography>
                </Box>
            )}
        </Box>
    );
};

// Add prop types validation
CodeAvailabilityCheck.propTypes = {
    code: PropTypes.string.isRequired,
    validFrom: PropTypes.instanceOf(Date),
    validUntil: PropTypes.instanceOf(Date),
    overlappingPeriods: PropTypes.arrayOf(PropTypes.shape({
        valid_from: PropTypes.string,
        valid_until: PropTypes.string
    })),
    isChecking: PropTypes.bool,
    paidCode: PropTypes.oneOfType([  // Allow both string and object
        PropTypes.string,
        PropTypes.object
    ])
};

// Add styled components
const DropZone = styled(Box, {
    shouldForwardProp: (prop) => prop !== 'isDragging'
})(({ theme, isDragging }) => ({
    width: '100%',
    height: '400px',
    border: `2px dashed ${isDragging ? theme.palette.primary.main : theme.palette.divider}`,
    borderRadius: theme.shape.borderRadius,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: theme.spacing(2),
    cursor: 'pointer',
    transition: 'border-color 0.2s ease',
    backgroundColor: isDragging ? theme.palette.action.hover : 'transparent',
    '&:hover': {
        borderColor: theme.palette.primary.main,
        backgroundColor: theme.palette.action.hover
    }
}));

// Update the AvailabilityStatus component
const AvailabilityStatus = ({ isAvailable, overlappingPeriods }) => {
    if (isAvailable) {
        return (
            <Box sx={{ 
                display: 'flex', 
                alignItems: 'center',
                gap: 2,
                p: 1
            }}>
                <CheckCircleIcon color="success" />
                <Typography sx={{ color: theme => theme.palette.success.main }}>
                    Code is available for selected period
                </Typography>
            </Box>
        );
    }

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            <Typography sx={{ p: 1, color: theme => theme.palette.error.main }}>
                Code is already in use during:
            </Typography>
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                {overlappingPeriods.map((period, index) => (
                    <Box 
                        key={index} 
                        sx={{ 
                            p: 1,
                            '&:not(:last-child)': {
                                borderBottom: '1px solid',
                                borderColor: 'divider'
                            }
                        }}
                    >
                        <Typography variant="body2" sx={{ 
                            display: 'flex',
                            gap: 1,
                            color: theme => theme.palette.text.primary
                        }}>
                            <span>
                                {format(new Date(period.valid_from), 'MMM dd, yyyy')} - {format(new Date(period.valid_until), 'MMM dd, yyyy')}
                            </span>
                            <Box 
                                component="span" 
                                sx={{ 
                                    display: { xs: 'none', sm: 'inline' },
                                    color: theme => theme.palette.text.secondary 
                                }}
                            >
                                /
                            </Box>
                            <span sx={{ color: theme => theme.palette.text.secondary }}>
                                {period.code_url}
                            </span>
                        </Typography>
                    </Box>
                ))}
            </Box>
        </Box>
    );
};

const CodeForm = ({ open, onClose, editingCode, onCodeSaved }) => {
    const [activeStep, setActiveStep] = useState(0);
    const [codeForm, setCodeForm] = useState({
        code_url: '',
        valid_from: new Date(),
        valid_until: addMonths(new Date(), 1),
        code_value: '',
        code_name: '',
        code_description: '',
        image_url: ''
    });
    const [formErrors, setFormErrors] = useState({});
    const [availableCodes, setAvailableCodes] = useState([]);
    const [overlappingCodes, setOverlappingCodes] = useState([]);
    const [codeInput, setCodeInput] = useState('');
    const [suggestedCodes, setSuggestedCodes] = useState([]);
    const [isLoadingSuggestions, setIsLoadingSuggestions] = useState(false);
    const [overlappingCode, setOverlappingCode] = useState(null);
    const [validationStatus, setValidationStatus] = useState({
        format: { status: 'inactive' },
        preview: { status: 'inactive' },
        existing: { status: 'inactive' }
    });
    const [selectedCodes, setSelectedCodes] = useState([]);
    const [codeSuggestions, setCodeSuggestions] = useState([]);
    const [overlappingPeriods, setOverlappingPeriods] = useState([]);
    const [checkingAvailability, setCheckingAvailability] = useState(false);
    const [codeAvailability, setCodeAvailability] = useState({
        available: false,
        paidCode: null,
        overlappingPeriods: []
    });
    const [allPaidCodes, setAllPaidCodes] = useState([]);
    const [selectedCode, setSelectedCode] = useState(null);
    const [imageUploadOpen, setImageUploadOpen] = useState(false);
    const [isDragging, setIsDragging] = useState(false);
    const [crop, setCrop] = useState(undefined);
    const [completedCrop, setCompletedCrop] = useState(null);
    const [imageFile, setImageFile] = useState(null);
    const [previewUrl, setPreviewUrl] = useState(null);
    const [processedImage, setProcessedImage] = useState(null);
    const [isImageUploaded, setIsImageUploaded] = useState(false);
    const [showSuggestions, setShowSuggestions] = useState(false);
    const [filteredSuggestions, setFilteredSuggestions] = useState({
        paid: [],
        free: []
    });
    const [paidCodes, setPaidCodes] = useState([]);

    // Get user's timezone
    const userTimezone = moment.tz.guess();

    // Single fetch for paid codes when form opens
    useEffect(() => {
        if (open) {
            const fetchPaidCodes = async () => {
                try {
                    const response = await getPaidCodes();
                    setAllPaidCodes(response || []);
                    setCodeSuggestions(response || []); // Set both states at once
                } catch (error) {
                    console.error('Error fetching paid codes:', error);
                    setAllPaidCodes([]);
                    setCodeSuggestions([]);
                }
            };
            fetchPaidCodes();
        }
    }, [open]);

    // Filter paid codes based on selected period
    const getAvailablePaidCodes = useCallback(() => {
        if (!allPaidCodes.length) return [];
        
        return allPaidCodes.filter(paidCode => {
            // Check if this paid code number is used in any existing code
            // that overlaps with the selected period
            const existingCodes = overlappingPeriods.filter(period => 
                period.code_value.endsWith(paidCode.code)
            );
            return existingCodes.length === 0;
        });
    }, [allPaidCodes, overlappingPeriods]);

    // Use filtered codes when needed
    const handleNext = async () => {
        if (validateCurrentStep()) {
            if (activeStep === 2) {
                // No need to fetch, just filter the existing codes
                const availableCodes = getAvailablePaidCodes();
                setCodeSuggestions(availableCodes);
            }
            if (activeStep === 3) {
                // Add debug logs
                console.log('Selected Code:', selectedCode);
                console.log('Code Form:', codeForm);
                console.log('Code Availability:', codeAvailability);

                if (!selectedCode || !selectedCode.code_value) {
                    setFormErrors(prev => ({
                        ...prev,
                        code_value: 'Please select a valid code'
                    }));
                    return;
                }
            }
            if (activeStep === 4) {
                // Check content before proceeding
                try {
                    const response = await checkContentModeration({
                        code_name: codeForm.code_name,
                        code_description: codeForm.code_description
                    });
                    
                    if (!response.isAppropriate) {
                        setFormErrors({
                            ...formErrors,
                            code_name: response.nameIssues ? 'Inappropriate content detected' : undefined,
                            code_description: response.descriptionIssues ? 'Inappropriate content detected' : undefined
                        });
                        return;
                    }
                } catch (error) {
                    console.error('Error checking content:', error);
                    return;
                }
            }
            setActiveStep((prevStep) => prevStep + 1);
        }
    };

    const handleBack = () => {
        setActiveStep((prevStep) => prevStep - 1);
    };

    const handlePaidCodesFetch = async () => {
        try {
            setIsLoadingSuggestions(true);
            const response = await getPaidCodes(
                codeForm.valid_from.toISOString(),
                codeForm.valid_until.toISOString()
            );
            setCodeSuggestions(response || []);
        } catch (error) {
            console.error('Error fetching paid codes:', error);
            setCodeSuggestions([]);
        } finally {
            setIsLoadingSuggestions(false);
        }
    };

    const validateCurrentStep = () => {
        const errors = {};
        switch (activeStep) {
            case 0: // Guide
                return true;
            case 1: // URL
                // URL validation
                //if (!codeForm.code_url) {
                //    errors.code_url = 'URL is required';
                //}
                break;
            case 2: // Period
                if (!codeForm.valid_from || !codeForm.valid_until) {
                    errors.dates = 'Both dates are required';
                } else if (isBefore(codeForm.valid_until, codeForm.valid_from)) {
                    errors.dates = 'End date must be after start date';
                } else if (isAfter(codeForm.valid_until, addMonths(codeForm.valid_from, MAX_RENTAL_MONTHS))) {
                    errors.dates = `Maximum rental period is ${MAX_RENTAL_MONTHS} months`;
                }
                break;
            case 3: // Choose Code
                if (!selectedCode || !codeForm.code_value) {
                    errors.code_value = 'Please select a valid code';
                }
                break;
            case 4: // Add Details
                if (!codeForm.code_name.trim()) {
                    errors.code_name = 'Code name is required';
                }
                if (!codeForm.code_description.trim()) {
                    errors.code_description = 'Code description is required';
                }
                break;
            case 5: // Add Image
                // No validation required for image
                break;
        }
        setFormErrors(errors);
        return Object.keys(errors).length === 0;
    };

    const handleUrlChange = (e) => {
        const url = e.target.value;
        setCodeForm(prev => ({ ...prev, code_url: url }));
        
        // Reset validation status when URL changes
        setValidationStatus({
            format: { status: 'inactive' },
            preview: { status: 'inactive' },
            existing: { status: 'inactive' }
        });
        
        if (formErrors.code_url) {
            setFormErrors(prev => ({ ...prev, code_url: '' }));
        }
    };

    // Revert to original validation logic
    const shouldStartValidation = (url) => {
        if (!url) return false;
        
        try {
            // Remove protocol if present
            const urlNoProtocol = url.replace(/^(https?:\/\/)?(www\.)?/, '');
            
            // Split into parts
            const parts = urlNoProtocol.split('.');
            
            // Basic format validation rules:
            // 1. Must have at least domain and TLD (2 parts)
            // 2. TLD must be 2-6 characters
            // 3. Domain part must be at least 1 character
            // 4. If starts with 'www', it must be exactly 3 'w's
            if (url.toLowerCase().startsWith('www')) {
                if (!/^www\./i.test(url)) return false;
            }
            
            return parts.length >= 2 && 
                   parts[parts.length - 1].length >= 2 &&
                   parts[parts.length - 1].length <= 6 &&
                   parts[0].length >= 1;
        } catch (e) {
            return false;
        }
    };

    // Update the URL validation effect to set pending status before API call
    useEffect(() => {
        const validateUrlWithDelay = async () => {
            if (!codeForm.code_url) {
                setValidationStatus({
                    format: { status: 'inactive' },
                    preview: { status: 'inactive' },
                    existing: { status: 'inactive' }
                });
                return;
            }

            const canStartValidation = shouldStartValidation(codeForm.code_url);
            const baseMessage = 'Waiting for valid URL...';

            // First set the initial status
            setValidationStatus({
                format: {
                    status: canStartValidation ? 'success' : 'error',
                    message: canStartValidation ? 'Valid URL format' : 'Invalid URL format'
                },
                preview: {
                    status: canStartValidation ? 'pending' : 'inactive',  // Set to pending when validation starts
                    message: canStartValidation ? 'Generating preview...' : baseMessage
                },
                existing: {
                    status: canStartValidation ? 'pending' : 'inactive',  // Set to pending when validation starts
                    message: canStartValidation ? 'Checking existing codes...' : baseMessage
                }
            });

            if (!canStartValidation) return;

            try {
                const result = await validateUrl(codeForm.code_url);
                setValidationStatus(prev => ({
                    ...prev,
                    preview: result.validations.preview,
                    existing: result.validations.existing
                }));
            } catch (error) {
                setValidationStatus(prev => ({
                    ...prev,
                    preview: { status: 'error', message: 'Failed to generate preview' },
                    existing: { status: 'error', message: 'Failed to check existing codes' }
                }));
            }
        };

        const timeoutId = setTimeout(validateUrlWithDelay, 1000);
        return () => clearTimeout(timeoutId);
    }, [codeForm.code_url]);

    // Effect for handling returns to Code step
    useEffect(() => {
        if (activeStep === 3) {
            // Reset validation state when entering step
            setCodeAvailability({
                isAvailable: false,
                overlappingPeriods: []
            });
            setCheckingAvailability(false);

            // If code is complete, validate with current dates
            if (CODE_REGEX.test(codeInput)) {
                checkCodeAvailability(codeInput);
            }
        }
    }, [activeStep]); // Only depend on step changes

    // Separate effect for handling code/date changes
    useEffect(() => {
        if (activeStep === 3 && CODE_REGEX.test(codeInput)) {
            checkCodeAvailability(codeInput);
        }
    }, [codeInput, codeForm.valid_from, codeForm.valid_until]); // Don't include activeStep

    const getRandomCodeFromCategory = (codes, categoryId) => {
        const categoryCodes = codes.filter(code => code.category_id === categoryId);
        if (categoryCodes.length === 0) return null;
        return categoryCodes[Math.floor(Math.random() * categoryCodes.length)];
    };

    const checkOverlappingCodes = async (code) => {
        try {
            const response = await checkCodeAvailabilityApi({
                code,
                valid_from: codeForm.valid_from,
                valid_until: codeForm.valid_until
            });
            
            setOverlappingCode(response.overlappingCode || null);
            return !response.overlappingCode;
        } catch (error) {
            console.error('Error checking code availability:', error);
            return false;
        }
    };

    // Update generateCodeSuggestions to properly handle paid code numbers
    const generateCodeSuggestions = useCallback((input) => {
        if (!input) return { paid: [], free: [] };
        
        const letterPrefix = input.replace(/[0-9]/g, '').toUpperCase();
        const numberPart = input.replace(/[A-Z]/g, '');
        
        if (letterPrefix.length < 2) return { paid: [], free: [] };

        // Filter paid codes that match current input pattern
        const matchingPaid = allPaidCodes
            .filter(paidCode => {
                const codeNumber = paidCode.code?.toString() || '';  // Use code field from API response
                // If user has entered numbers, filter by them
                if (numberPart) {
                    return codeNumber.startsWith(numberPart);
                }
                return true; // Show all paid codes if no numbers entered yet
            })
            .slice(0, 3) // Take top 3 paid suggestions
            .map(paidCode => ({
                value: `${letterPrefix}${paidCode.code}`,  // Combine prefix with paid code number
                isPaid: true,
                price: parseFloat(paidCode.price) || 50  // Parse price from API response
            }));

        // Generate random free codes that match the input pattern
        const generateMatchingNumber = () => {
            const remainingLength = 4 - numberPart.length;
            const randomPart = Array(remainingLength)
                .fill(0)
                .map(() => Math.floor(Math.random() * 10))
                .join('');
            return numberPart + randomPart;
        };

        // Generate free suggestions, excluding numbers used in paid codes
        const usedNumbers = new Set(allPaidCodes.map(pc => pc.code?.toString()));
        const freeSuggestions = Array(5).fill(null).map(() => {
            let number;
            do {
                number = generateMatchingNumber();
            } while (usedNumbers.has(number));
            
            return {
                value: `${letterPrefix}${number}`,
                isPaid: false
            };
        });

        return {
            paid: matchingPaid,
            free: freeSuggestions
        };
    }, [allPaidCodes]);

    // Separate function for availability check
    const checkCodeAvailability = async (code) => {
        setCheckingAvailability(true);
        try {
            const result = await checkCodeAvailabilityApi({
                code: code,
                valid_from: codeForm.valid_from,
                valid_until: codeForm.valid_until,
                exclude_code_id: editingCode?.code_id
            });
            setCodeAvailability(result);
            
            // If code is available, update both selectedCode and codeForm
            if (result.isAvailable) {
                const codeData = {
                    code_value: code,
                    paid_code: null
                };
                setSelectedCode(codeData);
                // Also update codeForm immediately
                setCodeForm(prev => ({
                    ...prev,
                    code_value: code
                }));
            } else {
                setSelectedCode(null);
            }
        } catch (error) {
            console.error('Error checking code availability:', error);
            setCodeAvailability({
                isAvailable: false,
                overlappingPeriods: []
            });
            setSelectedCode(null);
        } finally {
            setCheckingAvailability(false);
        }
    };

    const handleImageReady = async (processedImage) => {
        try {
            // Create local preview URL for the processed image
            const processedPreviewUrl = URL.createObjectURL(processedImage);
            
            // Store processed image for later S3 upload during CREATE
            setProcessedImage(processedImage);
            
            // Update preview with processed image
            setPreviewUrl(processedPreviewUrl);
            
            // Clear crop as we're done with it
            setCrop(null);
            
            // Enable CREATE button and disable UPLOAD button
            setIsImageUploaded(true);
            
            // Don't upload to S3 here - move it to handleSubmit
        } catch (error) {
            console.error('Error processing image:', error);
            setIsImageUploaded(false);
        }
    };

    const handleDragOver = (e) => {
        e.preventDefault();
        setIsDragging(true);
    };

    const handleDragLeave = () => {
        setIsDragging(false);
    };

    const handleDrop = async (e) => {
        e.preventDefault();
        setIsDragging(false);
        const file = e.dataTransfer.files[0];
        if (file) {
            await handleImageSelect(file);
        }
    };

    const handleFileSelect = async (e) => {
        const file = e.target.files[0];
        if (file) {
            await handleImageSelect(file);
        }
    };

    const handleImageSelect = async (file) => {
        if (!file || !file.type.startsWith('image/')) {
            setFormErrors(prev => ({
                ...prev,
                image: 'Please select a valid image file'
            }));
            return;
        }
        
        try {
            // Optimize image
            const optimizedFile = await optimizeImage(file);
            setImageFile(optimizedFile);

            // Create and set preview
            const preview = await createImagePreview(optimizedFile);
            setPreviewUrl(preview);

            // Calculate and set initial crop
            const initialCrop = await calculateInitialCrop(preview);
            setCrop(initialCrop);

        } catch (error) {
            console.error('Error processing image:', error);
            setFormErrors(prev => ({
                ...prev,
                image: 'Error processing image'
            }));
        }
    };

    const handleCropComplete = async (crop) => {
        if (!previewUrl || !crop.width || !crop.height) return;

        try {
            const croppedBlob = await getCroppedImage(previewUrl, crop);
            setImageFile(croppedBlob);
            // Reset upload status when crop changes
            setIsImageUploaded(false);
        } catch (error) {
            console.error('Error cropping image:', error);
        }
    };

    const renderStepContent = () => {
        // Extract letter prefix and number part here since they're used in rendering
        const letterPrefix = codeInput.replace(/[0-9]/g, '');
        const numberPart = codeInput.replace(/[A-Z]/g, '');
        switch (activeStep) {
            case 0:
                return (
                    <Box>
                        <Typography variant="h5" sx={{ 
                            mt: { xs: 1, sm: 0 },
                            mb: { xs: 1, sm: 1 },
                            fontSize: {
                                xs: '1.25rem',  // Smaller font on mobile
                                sm: '1.5rem'    // Original h5 size on larger screens
                            }
                        }}>
                            Before we start...
                        </Typography>
                        <Typography variant="subtitle1" color="primary.main" gutterBottom
                         sx={{ mb: 0,
                            fontSize: {
                                xs: '1.0rem',  // Smaller font on mobile
                                sm: '1.5rem'    // Original h5 size on larger screens
                            }
                         }}>
                             FORMAT
                        </Typography>
                        <Typography variant="h5" sx={{ 
                            mb: 1,
                            fontSize: {
                                xs: '1.0rem',  // Smaller font on mobile
                                sm: '1.5rem'    // Original h5 size on larger screens
                            }
                        }}>
                            We reccomend to use this format: KL0101 or LON3003</Typography>
                        <Typography variant="subtitle1" color="primary.main" gutterBottom
                         sx={{ mb: 0,
                            fontSize: {
                                xs: '1.0rem',  // Smaller font on mobile
                                sm: '1.2rem'    // Original h5 size on larger screens
                            }
                         }}>
                            First 2 or 3 Letters
                        </Typography>
                        <Typography variant="body2"
                        sx={{ mb: 1,
                            fontSize: {
                                xs: '0.8rem',  // Smaller font on mobile
                                sm: '1.0rem'    // Original h5 size on larger screens
                            }
                         }}>
                            Choose a country (e.g., NL, US) or city (KL, NY, AMS, LON) prefix to make your code easy to remember for local users.
                        </Typography>
                        <Typography variant="subtitle1" color="primary.main" gutterBottom
                        sx={{ mb: 0,
                            fontSize: {
                                xs: '1.0rem',  // Smaller font on mobile
                                sm: '1.2rem'    // Original h5 size on larger screens
                            }
                         }}>
                              Last 4 Digits
                        </Typography>
                        <Typography variant="body2"
                        sx={{ mb: 1,
                            fontSize: {
                                xs: '0.8rem',  // Smaller font on mobile
                                sm: '1.0rem'    // Original h5 size on larger screens
                            }
                         }}>
                          • Designed to be easy to remember at a glance or after hearing it once.<br/>
                          • Select from thousands of free numbers or opt for premium sequences (e.g., xx0101, xxx7777) for a small fee.
                        </Typography>
                        <Typography variant="subtitle1" color="primary.main" gutterBottom
                         sx={{ mb: 0 }}>
                             DURATION
                        </Typography>
                        <Typography variant="body2"
                        sx={{ mb: { xs: 1, sm: 2 },
                            fontSize: {
                                xs: '0.8rem',  // Smaller font on mobile
                                sm: '1.0rem'    // Original h5 size on larger screens
                            }
                         }}>
                           • Valid for up to 3 months from its start date<br/>
                           • Renew your code to keep it active long-term<br/>
                           • Set a future activation date to ensure your code starts working exactly when you need it
                        </Typography>
                        <Typography variant="subtitle1" color="primary.main" gutterBottom
                         sx={{ mb: 0 }}>
                           FEES
                        </Typography>
                        <Typography variant="body2"
                        sx={{ mb: 0,
                            fontSize: {
                                xs: '0.8rem',  // Smaller font on mobile
                                sm: '1.0rem'    // Original h5 size on larger screens
                            }
                         }}>
                            • Get your first 3 Codes for free<br/>
                            • Link your free Codes to a single URL and use them across different locations
                            Example: NY3322, LA3322, and CH3322 can all direct to the same web page
                        </Typography>
                    </Box>
                );
            case 1:
                return (
                    <Box>
                        <Typography variant="h5" sx={{ mb: 3,
                            color: 'text.primary',
                            fontSize: {
                                xs: '1.25rem',  // Smaller font on mobile
                                sm: '1.5rem'    // Original h5 size on larger screens
                            }
                         }}>
                            Please enter the web page you want your Code to refer
                        </Typography>
                        
                        <StyledTextField
                            fullWidth
                            label="URL"
                            value={codeForm.code_url}
                            onChange={handleUrlChange}
                            error={!!formErrors.code_url}
                            helperText={formErrors.code_url}
                            placeholder="www.example.com"
                            InputProps={{
                                sx: { fontSize: '1.1rem', py: 0,
                                    fontSize: {
                                        xs: '1.0rem',  // Smaller font on mobile
                                        sm: '1.0rem'    // Original h5 size on larger screens
                                    }
                                }
                            }}
                        />

                        <Box sx={{ 
                            mt: 3,
                            display: 'flex',
                            flexDirection: 'column',
                           // gap: { xs: 1, sm: 2 },
                            flex: 1
                         }}>
                            <ValidationLine 
                                label="URL Format"
                                status={!codeForm.code_url ? 'inactive' : validationStatus.format.status}
                                content={
                                    <Typography 
                                        component="span"
                                        color={
                                            !codeForm.code_url ? 'text.disabled' : 
                                            validationStatus.format.status === 'success' ? 'success.main' :
                                            validationStatus.format.status === 'error' ? 'error.main' :
                                            'text.primary'
                                        } sx={{
                                            fontSize: { xs: '1.0rem', sm: '1.2rem' }
                                        }}
                                    >
                                        {!codeForm.code_url ? 'Waiting for URL input...' : validationStatus.format.message}
                                    </Typography>
                                }
                            />

                            <ValidationLine 
                                label="URL Preview"
                                status={!codeForm.code_url ? 'inactive' : validationStatus.preview.status}
                                content={
                                    validationStatus.preview.status === 'pending' ? (
                                        <Typography color="text.primary" sx={{
                                            fontSize: { xs: '1.0rem', sm: '1.2rem' } }}  >
                                            {validationStatus.preview.message}
                                        </Typography>
                                    ) : validationStatus.preview.screenshot ? (
                                        <Card sx={{ width: 120, height: 120, flexShrink: 0 }}>
                                            <CardMedia
                                                component="img"
                                                image={validationStatus.preview.screenshot}
                                                alt="Website preview"
                                                sx={{ width: '100%', height: '100%', objectFit: 'cover' }}
                                            />
                                        </Card>
                                    ) : (
                                        <Typography color={!codeForm.code_url ? 'text.disabled' : 'text.primary'}>
                                            {validationStatus.preview.message}
                                        </Typography>
                                    )
                                }
                            />

                            <ValidationLine 
                                label="Existing Codes"
                                status={!codeForm.code_url ? 'inactive' : validationStatus.existing.status}
                                content={
                                    validationStatus.existing.codes?.length > 0 ? (
                                        <Box sx={{ 
                                            maxHeight: { xs: 230, sm: 200 }, 
                                            overflowY: 'auto',
                                            '&::-webkit-scrollbar': {
                                                width: '8px'
                                            },
                                            '&::-webkit-scrollbar-thumb': {
                                                backgroundColor: 'action.hover',
                                                borderRadius: '4px'
                                            }
                                        }}>
                                            {validationStatus.existing.codes.map((code, index) => (
                                                <Box key={index} sx={{ 
                                                    display: 'flex', 
                                                    flexWrap: { xs: 'wrap', sm: 'nowrap' },  // Wrap on mobile only
                                                    alignItems: 'center', 
                                                    gap: { xs: 0.0, sm: 2 },
                                                    mb: 1 
                                                }}>
                                                    {/* This container keeps code and chip together */}
                                                    <Box sx={{ 
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        gap: { xs: 1, sm: 2 },
                                                        minWidth: { xs: '100%', sm: 'auto' }  // Full width on mobile
                                                    }}>
                                                        <Typography variant="body2">
                                                            {code.code_value}
                                                        </Typography>
                                                        <Chip 
                                                            label={code.status}
                                                            size="small"
                                                            sx={{
                                                                borderRadius: '4px',     // Sharper corners
                                                                minWidth: '90px',        // Fixed width - adjust as needed
                                                                backgroundColor: 
                                                                    code.status === 'ACTIVE' ? '#4CAF50' :    // Custom green for Active
                                                                    code.status === 'RESERVED' ? '#2196F3' :  // Custom blue for Reserved
                                                                    'default',
                                                                color: '#ffffff',        // White text
                                                                '& .MuiChip-label': {
                                                                    padding: '0 8px',    // Adjust padding
                                                                    textAlign: 'left',   // Left-align text
                                                                    width: '100%',       // Ensure label takes full width
                                                                    fontWeight: 500      // Medium font weight
                                                                }
                                                            }}
                                                        />
                                                    </Box>
                                                    <Typography 
                                                        variant="caption" 
                                                        color="text.secondary"
                                                        sx={{
                                                            width: { xs: '100%', sm: 'auto' },  // Full width on mobile
                                                            pl: { xs: 0, sm: 0 }  // Add left padding on mobile
                                                        }}
                                                    >
                                                        ({format(new Date(code.valid_from), 'dd-MMM-yyyy')} - 
                                                        {format(new Date(code.valid_until), 'dd-MMM-yyyy')})
                                                    </Typography>
                                                </Box>
                                            ))}
                                        </Box>
                                    ) : (
                                        <Typography 
                                            color={!codeForm.code_url ? 'text.disabled' : 'text.primary'}
                                            sx={{
                                                fontSize: { xs: '1.0rem', sm: '1.2rem' }
                                            }}
                                        >
                                            {validationStatus.existing.message}
                                        </Typography>
                                    )
                                }
                            />
                        </Box>
                    </Box>
                );
            case 2:
                return (
                    <Box>
                        <Typography variant="h5" sx={{ mb: 3,
                            color: 'text.primary',
                            fontSize: {
                                xs: '1.25rem',  // Smaller font on mobile
                                sm: '1.5rem'    // Original h5 size on larger screens
                            }
                         }}>
                            {editingCode ? 'Code Period' : 'Select Code Period'}
                        </Typography>
                        
                        {editingCode ? (
                            // Read-only view for edit mode
                            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3 }}>
                                <TextField
                                    fullWidth
                                    label="Valid From"
                                    value={format(new Date(codeForm.valid_from), 'PPP')}
                                    InputProps={{ readOnly: true }}
                                />
                                <TextField
                                    fullWidth
                                    label="Valid Until"
                                    value={format(new Date(codeForm.valid_until), 'PPP')}
                                    InputProps={{ readOnly: true }}
                                />
                            </Box>
                        ) : (
                            // Original date picker implementation for create mode
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                <Box sx={{ mt: 4 }}>
                                    {/* <Typography variant="h6" sx={{ mb: 3, color: 'text.primary' }}>
                                        Select rental period
                                    </Typography> */}
                                    {formErrors.dates && (
                                        <Alert severity="error" sx={{ mb: 2 }}>{formErrors.dates}</Alert>
                                    )}
                                    <Box sx={{ 
                                        display: 'flex', 
                                        flexDirection: 'column',
                                        gap: 3
                                    }}>
                                        <Box>
                                            <Typography 
                                                variant="caption" 
                                                sx={{ 
                                                    color: 'text.secondary',
                                                    mb: 1,
                                                    display: 'block'
                                                }}
                                            >
                                                Valid From
                                            </Typography>
                                            <DatePicker
                                                value={codeForm.valid_from}
                                                onChange={(date) => setCodeForm(prev => ({ 
                                                    ...prev, 
                                                    valid_from: date,
                                                    valid_until: isBefore(prev.valid_until, date) 
                                                        ? addMonths(date, 1) 
                                                        : prev.valid_until
                                                }))}
                                                minDate={new Date()}
                                                inputFormat="dd-MMM-yyyy"
                                                renderInput={(params) => (
                                                    <StyledDateField
                                                        {...params}
                                                        fullWidth
                                                    />
                                                )}
                                            />
                                        </Box>
                                        <Box>
                                            <Typography 
                                                variant="caption" 
                                                sx={{ 
                                                    color: 'text.secondary',
                                                    mb: 1,
                                                    display: 'block'
                                                }}
                                            >
                                                Valid Until
                                            </Typography>
                                            <DatePicker
                                                value={codeForm.valid_until}
                                                onChange={(date) => setCodeForm(prev => ({ ...prev, valid_until: date }))}
                                                minDate={codeForm.valid_from}
                                                maxDate={addMonths(codeForm.valid_from, MAX_RENTAL_MONTHS)}
                                                inputFormat="dd-MMM-yyyy"
                                                renderInput={(params) => (
                                                    <StyledDateField
                                                        {...params}
                                                        fullWidth
                                                    />
                                                )}
                                            />
                                        </Box>
                                    </Box>
                                </Box>
                            </LocalizationProvider>
                        )}
                    </Box>
                );
            case 3:
                return (
                    <Box sx={{ 
                        display: 'flex', 
                        flexDirection: { xs: 'column', sm: 'row' }, // Vertical on mobile, horizontal on desktop
                        gap: { xs: 1, sm: 2 }  // Smaller gap on mobile
                    }}>
                        {/* Left section - Code Input */}
                        <Box sx={{
                            flex: { xs: '1', sm: '0 0 250px' } // Full width on mobile, fixed width on desktop
                        }}>
                            <Typography variant="subtitle1" sx={{ mb: 2 }}>
                                Enter Code
                            </Typography>
                            <StyledTextField
                                fullWidth
                                label="Code"
                                value={codeInput}
                                onChange={(e) => handleCodeInput(e.target.value)}
                                error={!!formErrors.code_value}
                                placeholder="NL0000 or AMS0000"
                                autoComplete="off"
                                inputProps={{
                                    maxLength: 7,
                                    style: { textTransform: 'uppercase' }
                                }}
                            />
                            {showSuggestions && (
                                <CodeSuggestions
                                    validationMessage={getValidationMessage(codeInput)}
                                    suggestions={filteredSuggestions}
                                    onSelect={(value) => {
                                        setCodeInput(value);
                                        setShowSuggestions(false);
                                    }}
                                />
                            )}
                        </Box>

                        {/* Combined Price and Success Message section */}
                        <Box sx={{
                            flex: { xs: '1', sm: 1 } // Full width on mobile, flexible on desktop
                        }}>
                            <Typography variant="subtitle1" sx={{ mb: 2 }}>
                                Price/Details
                            </Typography>
                            <Box sx={{ 
                                display: 'flex',
                                flexDirection: { xs: 'column', sm: 'row' }, // Vertical on mobile
                                alignItems: { xs: 'flex-start', sm: 'center' },
                                gap: { xs: 2, sm: 3 },
                                minHeight: '56px'
                            }}>
                                {getValidationMessage(codeInput) === 'Valid code format' && (
                                    <>
                                        <CodePriceSection 
                                            code={codeInput} 
                                            price={codeAvailability.paidCode?.price || 0} 
                                        />
                                        {!codeAvailability.overlappingPeriods?.length && !checkingAvailability && (
                                            <Box sx={{ 
                                                display: 'flex', 
                                                alignItems: 'center',
                                                gap: 1
                                            }}>
                                                <CheckCircleIcon color="success" />
                                                <Typography sx={{ color: theme => theme.palette.success.main }}>
                                                    Code is available for selected period
                                                </Typography>
                                            </Box>
                                        )}
                                    </>
                                )}
                            </Box>

                            {/* Conflict Messages */}
                            {codeAvailability.overlappingPeriods?.length > 0 && (
                                <Box sx={{ 
                                    mt: { xs: 1, sm: 2 },
                                    width: '100%' // Full width on both mobile and desktop
                                }}>
                                    <Typography color="error.main" sx={{ mb: 1 }}>
                                        {editingCode 
                                            ? 'Code is already in use. Please choose different Code value'
                                            : 'Code is already in use. Please change dates or Code value'
                                        }
                                    </Typography>
                                    <Box sx={{ 
                                        gap: { xs: 1, sm: 1 },
                                        display: 'flex', 
                                        flexDirection: 'column',
                                        width: '100%'
                                    }}>
                                        {codeAvailability.overlappingPeriods.map((period, index) => (
                                            <Box 
                                                key={index} 
                                                sx={{ 
                                                    p: { xs: 0, sm: 0 },
                                                    width: '100%',
                                                    '&:not(:last-child)': {
                                                        borderBottom: '1px solid',
                                                        borderColor: 'divider'
                                                    }
                                                }}
                                            >
                                                <Typography variant="body2" sx={{ 
                                                    display: 'flex',
                                                    flexDirection: { xs: 'column', sm: 'row' },
                                                    gap: { xs: 0, sm: 1 },
                                                    color: theme => theme.palette.text.primary
                                                }}>
                                                    <span>
                                                        {format(new Date(period.valid_from), 'MMM dd, yyyy')} - {format(new Date(period.valid_until), 'MMM dd, yyyy')}
                                                    </span>
                                                    <Box 
                                                        component="span" 
                                                        sx={{ 
                                                            display: { xs: 'none', sm: 'inline' },
                                                            color: theme => theme.palette.text.secondary 
                                                        }}
                                                    >
                                                        /
                                                    </Box>
                                                    <span sx={{ color: theme => theme.palette.text.secondary }}>
                                                        {period.code_url}
                                                    </span>
                                                </Typography>
                                            </Box>
                                        ))}
                                    </Box>
                                </Box>
                            )}
                        </Box>
                    </Box>
                );
            case 4:
                return (
                    <Box>
                        <Typography variant="h6" sx={{ mb: 3 }}>
                            Add Details
                        </Typography>
                        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3 }}>
                            <TextField
                                fullWidth
                                label="Code Name"
                                value={codeForm.code_name}
                                onChange={(e) => {
                                    const value = e.target.value.slice(0, 20); // Limit to 20 chars
                                    setCodeForm(prev => ({ ...prev, code_name: value }));
                                }}
                                error={!!formErrors.code_name}
                                helperText={formErrors.code_name || `${codeForm.code_name.length}/20 characters`}
                                placeholder="Enter a name for your code"
                            />
                            <TextField
                                fullWidth
                                label="Code Description"
                                value={codeForm.code_description}
                                onChange={(e) => {
                                    const value = e.target.value.slice(0, 120); // Limit to 120 chars
                                    setCodeForm(prev => ({ ...prev, code_description: value }));
                                }}
                                error={!!formErrors.code_description}
                                helperText={formErrors.code_description || `${codeForm.code_description.length}/120 characters`}
                                placeholder="Describe what this code is for"
                                multiline
                                rows={4}
                            />
                        </Box>
                    </Box>
                );
            case 5:
                return (
                    <Box>
                        <Typography variant="h6" sx={{ mb: 3 }}>
                            Add Image
                        </Typography>
                        <Box sx={{ 
                            width: '100%',
                            display: 'flex',
                            flexDirection: 'column',
                            gap: 2
                        }}>
                            {!previewUrl ? (
                                <DropZone
                                    isDragging={isDragging}
                                    onDragOver={handleDragOver}
                                    onDragLeave={handleDragLeave}
                                    onDrop={handleDrop}
                                >
                                    <CloudUploadIcon sx={{ fontSize: 48, mb: 2, color: 'text.secondary' }} />
                                    <Typography variant="body1" color="text.secondary" mb={2}>
                                        Drag and drop an image here, or
                                    </Typography>
                                    <Button
                                        variant="outlined"
                                        component="label"
                                    >
                                        Select from Files
                                        <input
                                            type="file"
                                            hidden
                                            accept="image/*"
                                            onChange={(e) => handleImageSelect(e.target.files[0])}
                                        />
                                    </Button>
                                </DropZone>
                            ) : (
                                <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                                    <Box sx={{ 
                                        height: '400px',
                                        overflow: 'hidden',
                                        borderRadius: 1,
                                      //  border: '1px solid',
                                        borderColor: 'divider',
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center'
                                    }}>
                                        {!codeForm.image_url ? (
                                            <ReactCrop
                                                crop={crop}
                                                onChange={(c) => setCrop(c)}
                                                onComplete={handleCropComplete}
                                                aspect={1}
                                            >
                                                <img
                                                    src={previewUrl}
                                                    style={{
                                                        maxWidth: '100%',
                                                        maxHeight: '400px',
                                                        objectFit: 'contain'
                                                    }}
                                                    alt="Preview"
                                                />
                                            </ReactCrop>
                                        ) : (
                                            <img
                                                src={codeForm.image_url}
                                                style={{
                                                    maxWidth: '100%',
                                                    maxHeight: '400px',
                                                    objectFit: 'contain'
                                                }}
                                                alt="Final"
                                            />
                                        )}
                                    </Box>
                                    <Box sx={{ 
                                        display: 'flex', 
                                        gap: 2,
                                        justifyContent: 'flex-end' 
                                    }}>
                                        <Button 
                                            onClick={handleClear}
                                            color="primary"
                                            variant="outlined"
                                        >
                                            CLEAR
                                        </Button>
                                        <Button 
                                            onClick={async () => {
                                                try {
                                                    const croppedBlob = await getCroppedImage(previewUrl, crop);
                                                    const optimizedFile = await optimizeImage(croppedBlob);
                                                    await handleImageReady(optimizedFile);
                                                } catch (error) {
                                                    console.error('Error processing image:', error);
                                                }
                                            }}
                                            color="primary"
                                            variant="contained"
                                            disabled={isImageUploaded}
                                        >
                                            UPLOAD
                                        </Button>
                                    </Box>
                                </Box>
                            )}
                        </Box>
                    </Box>
                );
            // Add other steps...
            default:
                return null;
        }
    };

    const handleClear = () => {
        // Clean up old preview URL if exists
        if (previewUrl) {
            URL.revokeObjectURL(previewUrl);
        }
        
        // Reset all image-related states
        setPreviewUrl(null);
        setImageFile(null);
        setCrop(null);
        setCompletedCrop(null);
        setProcessedImage(null);
        setIsImageUploaded(false);
        setCodeForm(prev => ({ 
            ...prev, 
            image_url: '',
            processedImage: null
        }));
    };

    const handleSubmit = async () => {
        if (!validateCurrentStep()) return;

        try {
            // Adjust dates to user's local midnight
            const validFrom = moment(codeForm.valid_from)
                .tz(userTimezone)
                .startOf('day')
                .add(1, 'second')  // 00:00:01
                .format();

            const validUntil = moment(codeForm.valid_until)
                .tz(userTimezone)
                .endOf('day')      // 23:59:59
                .format();

            let imageUrl = '';
            if (processedImage) {
                try {
                    imageUrl = await uploadImage(
                        processedImage,
                        editingCode?.image_url  // Add this parameter for edit mode
                    );
                } catch (error) {
                    console.error('Failed to upload image:', error);
                    setFormErrors(prev => ({
                        ...prev,
                        submit: 'Failed to upload image. Please try again.'
                    }));
                    return;
                }
            }

            const submitData = {
                ...codeForm,
                valid_from: validFrom,
                valid_until: validUntil,
                code_value: selectedCode?.code_value || codeForm.code_value,
                code_name: codeForm.code_name,
                code_description: codeForm.code_description,
                image_url: imageUrl || editingCode?.image_url
            };

            if (editingCode) {
                await updateCode(editingCode.code_id, submitData);
            } else {
                await createCode(submitData);
            }
            onCodeSaved();
            handleClose();
        } catch (error) {
            console.error('Failed to save code:', error);
            setFormErrors(prev => ({
                ...prev,
                submit: 'Failed to save code. Please try again.'
            }));
        }
    };

    const handleClose = () => {
        // Clean up any object URLs to prevent memory leaks
        if (previewUrl) {
            URL.revokeObjectURL(previewUrl);
        }
        
        setCodeForm({
            code_url: '',
            valid_from: new Date(),
            valid_until: addMonths(new Date(), 1),
            code_value: '',
            code_name: '',
            code_description: '',
            image_url: '',
            processedImage: null
        });
        setProcessedImage(null);
        setPreviewUrl(null);
        setCrop(null);
        setFormErrors({});
        onClose();
    };

    // Update the isUrlValidationSuccessful function
    const isUrlValidationSuccessful = () => {
        return validationStatus.format?.status === 'success' && 
               validationStatus.preview?.status === 'success';
    };

    // Update title based on mode
    const getDialogTitle = () => {
        if (editingCode) {
            return `Edit Code: ${editingCode.code_value}`;
        }
        return 'Create New Code';
    };

    // Initialize form with existing values when in edit mode
    useEffect(() => {
        if (editingCode) {
            setCodeForm({
                code_url: editingCode.code_url || '',
                valid_from: editingCode.valid_from ? new Date(editingCode.valid_from) : null,
                valid_until: editingCode.valid_until ? new Date(editingCode.valid_until) : null,
                code_value: editingCode.code_value || '',
                code_name: editingCode.code_name || '',
                code_description: editingCode.code_description || '',
                image_url: editingCode.image_url ? `${editingCode.image_url}${editingCode.image_url.includes('?') ? '&' : '?'}v=${Date.now()}` : ''
            });

            // Pre-fill the code input in step 4
            if (editingCode.code_value) {
                setCodeInput(editingCode.code_value);
            }

            // Set up the image preview if image exists
            if (editingCode.image_url) {
                setPreviewUrl(`${editingCode.image_url}${editingCode.image_url.includes('?') ? '&' : '?'}v=${Date.now()}`);
                setIsImageUploaded(true);
            }
        }
    }, [editingCode]);

    // Update the next button disabled state
    const isNextButtonDisabled = () => {
        if (editingCode) {
            switch (activeStep) {
                case 1:
                    return !isUrlValidationSuccessful();
                case 2:
                case 3:
                    return false; // Always enabled in edit mode for these steps
                case 4:
                    return !codeForm.code_name || !codeForm.code_description;
                case 5:
                    return !isImageUploaded;
                default:
                    return false;
            }
        } else {
            return activeStep === 0 ? false :
                   activeStep === 1 ? !isUrlValidationSuccessful() :
                   activeStep === 2 ? !!formErrors.dates :
                   activeStep === 3 ? !selectedCode :
                   activeStep === 4 ? !codeForm.code_name || !codeForm.code_description :
                   activeStep === 5 ? !isImageUploaded :
                   false;
        }
    };

    // Update handleCodeInput function
    const handleCodeInput = (value) => {
        const upperValue = value.toUpperCase();
        
        // Extract current letters and numbers
        let letters = upperValue.replace(/[^A-Z]/g, '');
        let numbers = upperValue.replace(/[^A-Z]/g, '').length === 2 ? 
            upperValue.slice(2).replace(/[^0-9]/g, '') : 
            upperValue.slice(3).replace(/[^0-9]/g, '');
        
        // Apply rules
        letters = letters.slice(0, 3);  // Max 3 letters
        numbers = numbers.slice(0, 4);  // Max 4 numbers
        
        // Construct the formatted value
        const formattedValue = `${letters}${numbers}`;
        
        // Reset validation states if code changes
        if (formattedValue !== codeInput) {
            setCodeAvailability({
                overlappingPeriods: [],
                paidCode: null
            });
            setCheckingAvailability(false);
        }
        
        setCodeInput(formattedValue);
        
        // Show suggestions based on input state
        const isComplete = formattedValue.length === 6 || formattedValue.length === 7;
        const shouldShowSuggestions = !isComplete && formattedValue.length > 0;
        setShowSuggestions(shouldShowSuggestions);
        
        // Only generate suggestions when we have valid prefix
        if (letters.length >= 2 && !isComplete) {
            const suggestions = generateCodeSuggestions(formattedValue);
            setFilteredSuggestions(suggestions);
        } else {
            setFilteredSuggestions({ paid: [], free: [] });
        }
        
        // If code is complete, trigger availability check
        if (CODE_REGEX.test(formattedValue)) {
            handleCheckAvailability(formattedValue);
        }
    };

    // Add this helper function to handle availability checks
    const handleCheckAvailability = useCallback(
        debounce((code) => {
            if (CODE_REGEX.test(code)) {
                setCheckingAvailability(true);
                checkCodeAvailabilityApi(code, codeForm.valid_from, codeForm.valid_until)
                    .then(response => {
                        setCodeAvailability(response);
                    })
                    .catch(error => {
                        console.error('Error checking code availability:', error);
                    })
                    .finally(() => {
                        setCheckingAvailability(false);
                    });
            }
        }, 300),
        [codeForm.valid_from, codeForm.valid_until]
    );

    return (
        <Dialog 
            open={open} 
            onClose={onClose} 
            maxWidth="md" 
            fullWidth
            PaperProps={{
                sx: { 
                    height: { 
                        xs: '90%',    // Full height on mobile
                        sm: '100%',    // Full height on small tablets
                        md: '800px'    // Original fixed height on desktop
                    },
                    maxHeight: {
                        xs: '90%',    // Full height on mobile
                        sm: '100%',    // Full height on small tablets
                        md: '90vh'     // Limit height on desktop
                    },
                    width: {
                        xs: '90%',    // Full width on mobile
                        sm: '100%',     // 90% width on tablets
                        md: '800px'    // Fixed width on desktop
                    },
                    maxWidth: {
                        xs: '90%',    // Full width on mobile
                        sm: '100%',     // 90% width on tablets
                        md: '800px'    // Original max width on desktop
                    },
                    margin: {
                        xs: 0,         // No margin on mobile
                        sm: 2,         // Small margin on tablets
                        md: 3          // Original margin on desktop
                    },
                    display: 'flex',
                    flexDirection: 'column',
                    borderRadius: {
                        xs: 0,         // No border radius on mobile
                        sm: 1          // Border radius on larger screens
                    }
                }
            }}
        >
            <DialogTitle sx={{ 
                pt: { xs: 1, sm: 3 },  
                pb: { xs: 1, sm: 1 },   // Less padding on mobile
                px: { xs: 2, sm: 4 },     // Less padding on mobile
                typography: 'h5',
                color: 'text.primary',
                fontSize: { 
                    xs: '1.25rem',         // Smaller font on mobile
                    sm: '1.5rem'           // Original font size on larger screens
                }
            }}>
                {getDialogTitle()}
            </DialogTitle>
            
            <StyledStepper 
                activeStep={activeStep} 
                alternativeLabel 
                sx={{ 
                    px: { xs: 0, sm: 4 },  // Less padding on mobile
                    mb: { xs: 1, sm: 2 },  // Less margin on mobile
                    '& .MuiStepLabel-label': {
                        fontSize: {
                            xs: '0.75rem',  // Smaller font on mobile
                            sm: '0.875rem'  // Original font size on larger screens
                        }
                    }
                }}
            >
                {getSteps(editingCode).map((label) => (
                    <Step key={label}>
                        <StepLabel>{label}</StepLabel>
                    </Step>
                ))}
            </StyledStepper>

            <DialogContent sx={{ 
                px: { xs: 1, sm: 4 },      // Less padding on mobile
                flex: 1,
                overflow: 'hidden',
                py: 0
            }}>
                <Box sx={{
                    bgcolor: 'background.default',
                    borderRadius: { xs: 0, sm: 1 }, // No border radius on mobile
                    px: { xs: 1, sm: 2 }, 
                    py: { xs: 1, sm: 2 },  // Less padding on mobile
                    height: '100%',
                    my: 0,
                    overflow: 'auto'                // Changed from 'hidden' to 'auto' for mobile scrolling
                }}>
                    {renderStepContent()}
                </Box>
            </DialogContent>

            <DialogActions sx={{ 
                px: { xs: 1, sm: 4 },      // Less padding on mobile
                py: { xs: 1, sm: 3 },      // Less padding on mobile
                justifyContent: 'space-between',
                borderTop: '1px solid',     // Add border to separate from content
                borderColor: 'divider'
            }}>
                <Button 
                    onClick={handleBack}
                    sx={{ 
                        visibility: activeStep > 0 ? 'visible' : 'hidden',
                        color: 'text.secondary',
                        fontSize: {
                            xs: '0.875rem',  // Smaller font on mobile
                            sm: '1rem'       // Original font size on larger screens
                        }
                    }}
                >
                    BACK
                </Button>
                <Box sx={{ display: 'flex', gap: { xs: 1, sm: 2 } }}>
                    <Button 
                        onClick={onClose}
                        sx={{ 
                            color: 'text.secondary',
                            fontSize: {
                                xs: '0.875rem',  // Smaller font on mobile
                                sm: '1rem'       // Original font size on larger screens
                            }
                        }}
                    >
                        CANCEL
                    </Button>
                    <Button 
                        variant="contained" 
                        onClick={activeStep === getSteps(editingCode).length - 1 ? handleSubmit : handleNext}
                        disabled={isNextButtonDisabled()}
                        sx={{
                            fontSize: {
                                xs: '0.875rem',  // Smaller font on mobile
                                sm: '1rem'       // Original font size on larger screens
                            }
                        }}
                    >
                        {activeStep === getSteps(editingCode).length - 1 ? (editingCode ? 'SAVE' : 'CREATE') : 'NEXT'}
                    </Button>
                </Box>
            </DialogActions>
        </Dialog>
    );
};

CodeForm.propTypes = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    editingCode: PropTypes.object,
    onCodeSaved: PropTypes.func.isRequired
};

export default CodeForm; 