import React from 'react';
import { Box, Typography, useTheme } from '@mui/material';
import ImageIcon from '@mui/icons-material/Image';
import { logActivity } from '../services/api';

export const CodeHistoryItem = ({ code, onHistoryItemClick }) => {
    const theme = useTheme();
    
    const handleClick = async (e) => {
        e.preventDefault();
        
        // First - trigger URL navigation and log activity
        try {
            window.open(code.url, '_blank');
            await logActivity('visit_code', code.code, code.code_id, code.owner_id);
        } catch (error) {
            console.error('Error logging activity:', error);
        }

        // Then notify parent about the click to handle detailed code cleanup
        if (onHistoryItemClick) {
            onHistoryItemClick(code);
        }
    };
    
    return (
        <Box 
            component="a"
            href={code.url}
            target="_blank"
            onClick={handleClick}
            sx={{ 
                display: 'flex', 
                alignItems: 'center',
                gap: {xs: 0.8, sm: 2},
                width: '100%',
                p: {xs: 0.8, sm: 2},
                backgroundColor: theme.palette.background.tertiary,
                borderRadius: '12px',
                cursor: 'pointer',
                textDecoration: 'none',
                color: theme.palette.text.primary,
                transition: 'background-color 0.2s ease',
                '&:hover': {
                    backgroundColor: theme.palette.background.paper
                }
            }}
        >
            <Box
                sx={{
                    width: {xs: 40, sm: 60},
                    height: {xs: 40, sm: 60},
                    flexShrink: 0,
                    borderRadius: 1,
                    overflow: 'hidden',
                    backgroundColor: theme.palette.mode === 'dark' 
                        ? theme.palette.background.paper
                        : theme.palette.grey[200],
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center'
                }}
            >
                {code.imageUrl ? (
                    <img
                        src={code.imageUrl}
                        alt={code.code}
                        style={{
                            width: '100%',
                            height: '100%',
                            objectFit: 'cover'
                        }}
                    />
                ) : (
                    <ImageIcon 
                        sx={{
                            color: 'grey.600',
                            fontSize: {xs: 20, sm: 30}
                        }}
                    />
                )}
            </Box>
            <Box sx={{ 
                flex: 1,
                minWidth: 0
            }}>
                <Typography 
                    variant="body1"
                    noWrap
                    sx={{ 
                        color: theme.palette.text.primary,
                        fontSize: {xs: '0.8rem', sm: '1.1rem'},
                        lineHeight: 1.2,
                        fontWeight: 500,
                        mb: 0.1
                    }}
                >
                    {code.code}
                </Typography>
                <Typography 
                    variant="body2"
                    noWrap
                    sx={{ 
                        color: theme.palette.text.primary,
                        fontWeight: 'bold',
                        fontSize: {xs: '0.8rem', sm: '1rem'},
                        lineHeight: 1.2,
                        mb: 0.1
                    }}
                >
                    {code.codeName}
                </Typography>
                <Typography 
                    variant="body2"
                    noWrap
                    sx={{ 
                        color: theme.palette.text.secondary,
                        fontSize: {xs: '0.7rem', sm: '0.9rem'},
                        lineHeight: 1.2
                    }}
                >
                    {code.codeDescription}
                </Typography>
            </Box>
            <Box sx={{ 
                display: 'flex',
                alignItems: 'center',
                color: theme.palette.text.secondary,
                fontSize: {xs: '0.8rem', sm: '1.1rem'},
                ml: {xs: 0.5, sm: 1},
                mr: {xs: 0.5, sm: 2}
            }}>
                ›› 
            </Box>
        </Box>
    );
}; 