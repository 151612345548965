import React, { useState, useEffect, useRef } from 'react';
import { 
    Container, 
    Typography, 
    Box, 
    Grid,
    Paper,
    Button,
    IconButton,
    useTheme
} from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate } from 'react-router-dom';
import ImageSlideshow from '../components/ImageSlideshow';
import Header from '../components/Header';

const Section = ({ title, content, IllustrationComponent, reverse = false }) => (
    <Paper elevation={0} sx={{ p: 4, mb: 4, backgroundColor: 'transparent' }}>
        <Grid container spacing={4} direction={reverse ? 'row-reverse' : 'row'}>
            <Grid item xs={12} md={6}>
                <Typography variant="h5" gutterBottom color="primary">
                    {title}
                </Typography>
                <Typography variant="body1" sx={{ whiteSpace: 'pre-line' }}>
                    {content}
                </Typography>
            </Grid>
            <Grid item xs={12} md={6}>
                <Box sx={{ 
                    p: 4, 
                    backgroundColor: 'rgba(25, 118, 210, 0.05)',
                    borderRadius: 2,
                    height: '100%',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center'
                }}>
                    {IllustrationComponent}
                </Box>
            </Grid>
        </Grid>
    </Paper>
);

function AboutPage() {
    const navigate = useNavigate();
    const [slideshowImages, setSlideshowImages] = useState([[], [], []]);
    const [currentIndex, setCurrentIndex] = useState(0);
    const [imagesLoaded, setImagesLoaded] = useState(0);
    const [manualMode, setManualMode] = useState(false);
    const [manualTimeout, setManualTimeout] = useState(null);
    const BASE_INTERVAL = 5000; // Base interval of 5 seconds
    const slideshowRefs = useRef([
        useRef(null),
        useRef(null),
        useRef(null)
    ]);
    const theme = useTheme();
    
    // Update the allImages array with actual files from illustrations folder
    const allImages = [
        '/illustrations/buildboard01.png',
        '/illustrations/bus01.png',
        '/illustrations/construction01.png',
        '/illustrations/person01.jpg',
        '/illustrations/radioDJ01.jpg',
        '/illustrations/taxi01.png'
    ];

    // Update the getRandomImages function to group images thematically
    const getRandomImages = () => {
        // Create themed groups for each slideshow
        const outdoorAds = ['/illustrations/buildboard01.png', '/illustrations/construction01.png'];
        const transportAds = ['/illustrations/bus01.png', '/illustrations/taxi01.png'];
        const peopleAds = ['/illustrations/person01.jpg', '/illustrations/radioDJ01.jpg'];

        // Shuffle each group
        const shuffle = arr => [...arr].sort(() => 0.5 - Math.random());

        return [
            shuffle(outdoorAds),     // First slideshow - outdoor advertising
            shuffle(transportAds),    // Second slideshow - transport advertising
            shuffle(peopleAds)        // Third slideshow - people using Get-Code
        ];
    };

    // Initialize image groups
    useEffect(() => {
        setSlideshowImages(getRandomImages());
    }, []);

    // Update the auto-transition effect
    useEffect(() => {
        if (slideshowImages[0].length === 0 || manualMode) return;

        const timer = setInterval(() => {
            setCurrentIndex(current => {
                if (current === 0) {
                    return current + 1;
                }
                return (current + 1) % Math.min(
                    slideshowImages[0].length,
                    slideshowImages[1].length,
                    slideshowImages[2].length
                );
            });
        }, currentIndex === 0 ? BASE_INTERVAL * 1.5 : BASE_INTERVAL);

        return () => clearInterval(timer);
    }, [slideshowImages, currentIndex, manualMode]);

    // Update handleManualNavigate
    const handleManualNavigate = (direction) => {
        setManualMode(true);
        
        if (manualTimeout) {
            clearTimeout(manualTimeout);
        }

        const maxIndex = Math.min(
            slideshowImages[0].length,
            slideshowImages[1].length,
            slideshowImages[2].length
        );

        setCurrentIndex(current => {
            if (direction === 'prev') {
                return current === 0 ? maxIndex - 1 : current - 1;
            } else {
                return (current + 1) % maxIndex;
            }
        });

        const timeout = setTimeout(() => {
            setManualMode(false);
        }, 10000);

        setManualTimeout(timeout);
    };

    // Track image loading
    const handleImageLoad = () => {
        setImagesLoaded(prev => prev + 1);
    };

    return (
        <Box sx={{ 
            display: 'flex',
            flexDirection: 'column',
            minHeight: '100vh',
            backgroundColor: theme.palette.background.default
        }}>
            <Header />
            
            <Container 
                maxWidth="lg"
                sx={{ 
                    flex: 1,
                    display: 'flex',
                    flexDirection: 'column',
                    py: { xs: 3, sm: 4 }
                }}
            >
                {/* Back Button */}
                <Box sx={{ 
                    mb: 2,
                    mt: { xs: 4, sm: 6 }  }}>
                    <Button
                        startIcon={<ArrowBackIcon />}
                        onClick={() => navigate(-1)}
                        sx={{
                            color: theme.palette.text.secondary,
                            '&:hover': {
                                color: theme.palette.text.primary,
                                backgroundColor: 'transparent'
                            }
                        }}
                    >
                        BACK
                    </Button>
                </Box>

                {/* Main Title */}
                <Typography 
                    variant="h1"
                    align="center"
                    sx={{ 
                        color: theme.palette.text.primary,
                        fontSize: { xs: '1.75rem', sm: '2.25rem' },
                        mt: { xs: 2, sm: 5 },
                        mb: { xs: 2, sm: 3 },
                        fontWeight: 500
                    }}
                >
                    About Get-Code
                </Typography>

                {/* Main Description */}
                <Typography 
                    variant="body1"
                    align="center"
                    sx={{
                        fontSize: { xs: '0.9rem', sm: '1.1rem' },
                        color: theme.palette.text.secondary,
                        mb: { xs: 3, sm: 4 },
                        maxWidth: '800px',
                        mx: 'auto',
                        lineHeight: 1.6
                    }}
                >
                    Get-Code is a groundbreaking platform that revolutionizes the way people connect with products and services, making access easier, faster, and more intuitive.
                </Typography>

                <Box sx={{ mt: 6 }}>
                    <Typography 
                        variant="h2"
                        color="primary" 
                        sx={{ 
                            fontSize: { xs: '1.2rem', sm: '2.0rem' },
                            mb: { xs: 2, sm: 3 }
                        }}
                        gutterBottom
                    >
                        Discover with Ease, Access with Speed
                    </Typography>
                    
                    <Box sx={{ display: 'flex', gap: 4, alignItems: 'center', mb: 6 }}>
                        <Typography 
                            variant="body1" 
                            sx={{ 
                                flex: 1,
                                fontSize: { xs: '0.9rem', sm: '1.1rem' },
                                lineHeight: 1.6
                            }}
                        >
                            Picture this: you're driving past a billboard, listening to a radio ad, or walking through the city when a short, memorable code catches your attention—something like 'NY3344' or 'SG2023'. You don't have time to remember long URLs or complex names. With Get-Code, all you need is the code.
                            <br/><br/>
                            Simply enter the code in the Get-Code app or website, and you'll be instantly directed to the genuine web page of the advertised product or service. Say goodbye to confusing searches and fake links—Get-Code ensures you're always connected to what matters, quickly and securely.
                        </Typography>
                        <Box sx={{ flex: 1, height: 400 }}>
                            <ImageSlideshow 
                                images={slideshowImages[0]}
                                currentIndex={currentIndex}
                                onLoad={handleImageLoad}
                                onManualNavigate={handleManualNavigate}
                            />
                        </Box>
                    </Box>

                    <Box sx={{ display: 'flex', gap: 4, alignItems: 'center', mb: 6 }}>
                        <Box sx={{ flex: 1, height: 400 }}>
                            <ImageSlideshow 
                                images={slideshowImages[1]}
                                currentIndex={currentIndex}
                                onLoad={handleImageLoad}
                                onManualNavigate={handleManualNavigate}
                            />
                        </Box>
                        <Box sx={{ flex: 1 }}>
                            <Typography variant="h4" color="primary" gutterBottom>
                                Revolutionize Engagement, Amplify Impact
                            </Typography>
                            <Typography variant="body1">
                                Get-Code is the ultimate tool for advertisers to bridge the gap between attention and action. Replace long and forgettable URLs with simple, custom codes that stick in users' minds. Whether it's a visual ad on a billboard or a radio commercial, Get-Code ensures your audience can access the advertised page in seconds.
                            </Typography>
                        </Box>
                    </Box>

                    <Box sx={{ display: 'flex', gap: 4, alignItems: 'center' }}>
                        <Typography variant="body1" sx={{ flex: 1 }}>
                            <Typography variant="h4" color="primary" gutterBottom>
                                Secure, Reliable, and User-Friendly
                            </Typography>
                            Our platform is built with security at its core. Every code is unique and verified, ensuring users are directed only to legitimate destinations. For businesses, our analytics dashboard provides valuable insights into user engagement and campaign performance.
                        </Typography>
                        <Box sx={{ flex: 1, height: 400 }}>
                            <ImageSlideshow 
                                images={slideshowImages[2]}
                                currentIndex={currentIndex}
                                onLoad={handleImageLoad}
                                onManualNavigate={handleManualNavigate}
                            />
                        </Box>
                    </Box>
                </Box>
            </Container>
        </Box>
    );
}

export default AboutPage; 